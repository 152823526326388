import { useState } from "react";
import "./App.css";
import Dashboard from "./pages/dashboard";
import StartPage from "./pages/start";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Registration from "./pages/registration";
import Profile from "./pages/profile";
import { useEffect } from "react";
import Workout from "./pages/workout";
import YourWorkout from "./pages/workout/yourWorkout";
import Technique from "./pages/technique";
import MyData from "./pages/myProgress";
import AddProgress from "./pages/myProgress/addProgress";
import TriningData from "./pages/trainingData";
import Nutrition from "./pages/nutrition";
import Subsctibe from "./pages/subscribe";
import PhotoReport from "./pages/photoReport";
import NotFound from "./pages/404";
import ProfileUpdate from "./pages/profile/update";
import News from "./pages/news";
import AddPhotoReport from "./pages/photoReport/addPage";
import FullInfo from "./pages/technique/fullInfo";
import Help from "./pages/hepl";
import Reviews from "./pages/reviews";
import CustomSinglePage from "./pages/customs";
import AddReview from "./pages/reviews/addReview";
import { useDispatch } from "react-redux";
import { getUserExist } from "./store/features/auth/slice";
import { } from "./store/features/auth/selectors";
import LoadingComp from "./components/loading";

function App() {
  const [isLight, setIsLight] = useState(true);
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const tg = window.Telegram.WebApp;
  useEffect(() => {
    if (tg?.colorScheme === 'light') {
      setIsLight(true)
    } else {
      setIsLight(false)
    }
  }, [tg])


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await dispatch(getUserExist())
      if (res && (location.pathname === '/start' || location.pathname === '/')) {
        if (res.payload) {
          setLoading(false)
          navigate('/')
        } else {
          setLoading(false)
          navigate('/start')
        }
      }
      setLoading(false)

    }
    fetchData()
  }, [dispatch, location?.pathname, navigate])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [location.pathname])


  return (
    <div className={`App ${isLight ? "light" : ""}`}>
      {!loading ?
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/start" element={<StartPage />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/update" element={<ProfileUpdate />} />
          <Route path="/workout" element={<Workout />} />
          <Route path="/yourWorkout/:id" element={<YourWorkout />} />
          <Route path="/technique" element={<Technique />} />
          <Route path="/technique/:id" element={<FullInfo />} />
          <Route path="/news" element={<News />} />
          <Route path="/my_progress" element={<MyData />} />
          <Route path="/add_progress" element={<AddProgress />} />
          <Route path="/trining_data" element={<TriningData />} />
          <Route path="/nutrition" element={<Nutrition />} />
          <Route path="/subsctibe" element={<Subsctibe />} />
          <Route path="/photo_report" element={<PhotoReport />} />
          <Route path="/add_photo_report" element={<AddPhotoReport />} />
          <Route path="/help" element={<Help />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/addReview" element={<AddReview />} />
          <Route path="/custom/:type" element={<CustomSinglePage />} />
        </Routes>
        : <LoadingComp />}
    </div>
  );
}

export default App;
