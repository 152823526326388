import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {sendRequestWithToken} from "../../../api";

const initialState = {
    loading: false,
    techniques: {
        page: 1,
        count: 0,
        rows: [],
        search: '',
    },
    techniqueById: null,
};

export const getTechniques = createAsyncThunk(
    "auth/getTechniques",
    async (_, {getState, rejectWithValue}) => {
        try {
            const state = getState()
            const search = state.technique.techniques.search
            const page = state.technique.techniques.page
            const response = await sendRequestWithToken({
                method: 'POST',
                url: '/exercise',
                data: {
                    page,
                    limit: 10,
                    search
                }
            });

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const getTechniqueById = createAsyncThunk(
    "auth/getTechniqueById",
    async (id, {rejectWithValue}) => {
        try {
            const response = await sendRequestWithToken({
                method: 'GET',
                url: `/exercise/${id}`,
            });

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);


const techniqueSlice = createSlice({
    name: "technique",
    initialState,
    reducers: {
        setSearch: (state, action) => {
            state.techniques.search = action.payload
            state.techniques.page = 1
        },
        setPage: (state, action) => {
            state.techniques.page = action.payload
        },
        clearRows: (state, action) => {
            state.techniques.rows = []
        }
    },
    extraReducers(builder) {
        builder.addCase(getTechniques.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getTechniques.fulfilled, (state, action) => {
            state.loading = false;
            state.techniques.count = action.payload.count;

            if (state.techniques.search) {
                state.techniques.rows = action.payload.rows;
            } else {
                const existingIds = new Set(state.techniques.rows.map(row => row.id)); // Предполагается, что у вас есть уникальный идентификатор для каждого элемента, например, `id`
                const newRows = action.payload.rows.filter(row => !existingIds.has(row.id));
                state.techniques.rows = state.techniques.rows?.length ? [...state.techniques.rows, ...newRows] : action.payload.rows;
            }
        });
        builder.addCase(getTechniques.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getTechniqueById.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getTechniqueById.fulfilled, (state, action) => {
            state.loading = false;
            state.techniqueById = action.payload
        });
        builder.addCase(getTechniqueById.rejected, (state) => {
            state.loading = false;
        });

    },
});
export const {setSearch,setPage,clearRows} = techniqueSlice.actions
export default techniqueSlice.reducer;
