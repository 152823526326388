import React, { useEffect, useState } from "react";
import { StyledButton, StyledContent, StyledYourWorkout } from "./styled";
import PageTitle from "../../../components/pageTitle";
import Input from "../../../components/input";
import { PiWarningCircle } from "react-icons/pi";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
    selectExerciseByDay,
    selectExerciseCommentByDay,
    selectExerciseNameByDay
} from "../../../store/features/exercise/selectors";
import { editExercise, finishExercise, getExerciseWithDay } from "../../../store/features/exercise/slice";
import CalendarModal from "../../../components/modals/calendarModal";
import { selectAuthProfile } from "../../../store/features/auth/selectors";
import { getMyProfile } from "../../../store/features/auth/slice";
import { MdEdit } from "react-icons/md";
import ExerciseComment from "../../../components/exerciseComment";

const YourWorkout = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const data = useSelector(selectExerciseByDay())
    const name = useSelector(selectExerciseNameByDay())
    const comment = useSelector(selectExerciseCommentByDay())
    const myProfile = useSelector(selectAuthProfile())
    const [disableBtn, setDisableBtn] = useState(true)
    const [openModal, setOpenModal] = useState(false)
    const [updatedData, setUpdatedData] = useState([])

    let month = new Date().getMonth() + 1
    month = month < 10 ? `0${month}` : month
    const formatedDate = +params.id < 10 ? '0' + params?.id : params?.id
    const date = `${formatedDate}.${month}.${new Date().getFullYear()} `

    useEffect(() => {
        dispatch(getExerciseWithDay(params.id))
        return () => {
            setUpdatedData([])
        }
    }, [dispatch, params.id])

    useEffect(() => {
        if (!myProfile) {
            dispatch(getMyProfile())
        }
    }, [dispatch, myProfile])

    useEffect(() => {
        setUpdatedData(data)
    }, [data])
    useEffect(() => {
        if (myProfile) {
            const isDisabled = updatedData?.some(obj => obj.have_working_weight && !obj.working_weight)
            const isFinished = Object.values(myProfile?.UserExercise?.completed_days).includes(+params?.id)
            setDisableBtn(isDisabled || isFinished || +params?.id > new Date().getDate());
        }
    }, [updatedData, params?.id, myProfile]);

    const handleChange = (value, id, i) => {
        const newData = updatedData.map((item, index) => {
            if (id === item.id && i === index) {
                return {
                    ...item, working_weight: value
                }
            }
            return item
        })
        setUpdatedData(newData)
    }

    const handleSave = () => {
        const data = {
            user_id: myProfile.id, exercises: {
                [params.id]: {
                    exercises: updatedData, name, isDone: false
                },
            },
        };
        dispatch(editExercise(data)).then(res => {
            if (res.payload) {
                dispatch(finishExercise(+params.id)).then(res => {
                    if (res.payload) {
                        navigate(-1)
                    }
                })
            }
        })
    }
    return (<StyledYourWorkout>
        <PageTitle
            title={name}
            backFunc={() => navigate(-1)}
            editText={date}
            editIcon={myProfile?.UserExercise?.completed_days.includes(+params?.id) ? '' : <MdEdit />}
            editFunc={() => setOpenModal(true)}
        />
        {comment &&
            <>
                <ExerciseComment text={comment} />
                <br />
            </>
        }
        {updatedData?.map((item, index) => {
            return (<StyledContent key={index}>
                <p className="number">{index + 1}</p>
                <div className="flex">
                    <div className="inputs">
                        <Input label="Упражнение" disabled readOnly value={item.exercise} />
                        {item.quantity &&
                            <Input label="Количество" disabled readOnly value={item.quantity} />
                        }
                        {item.approaches &&
                            <Input label="Подходы" disabled readOnly value={item.approaches} />
                        }
                        {item.time &&
                            <Input label="Время" disabled readOnly value={item.time} />
                        }
                        {item?.have_working_weight ? <Input
                            label="Рабочий вес"
                            onchange={(e) => handleChange(e.target.value.replace(/\D/g, ''), item.id, index)}
                            value={item.working_weight || ''}
                        /> : ''}
                    </div>
                    <PiWarningCircle onClick={() => navigate(`/technique/${item.exercise_id}`)}
                        className="icon" />
                </div>
            </StyledContent>);
        })}
        <StyledButton
            disabled={disableBtn}
            onClick={handleSave}
        >
            Выполнено
            <FaAngleRight className="rightArrow" />
        </StyledButton>
        <br />
        <br />
        <br />
        <br />
        <CalendarModal user_id={myProfile?.id} allDays={myProfile?.UserExercise?.all_days} open={openModal}
            handleModal={() => setOpenModal(false)} />
    </StyledYourWorkout>);
};

export default YourWorkout;
