import styled from "styled-components";

export const DashboardWrapper = styled.div`
    max-width: 410px;
    padding: 45px 0;
    margin: 0 auto;
    .creator{
      width: 80%;
      margin: 0 auto;
      text-align: center;
      border-radius: 12px;
      background-color: #606060;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 0;
      a{
        font-size: 14px;
        font-weight: 500;
        color: white;
        text-decoration: none;
      }
    }
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .left {
    text-decoration: none;
    display: flex;
    color: var(--text1-color);
    align-items: center;
    gap: 5px;
    p {
      font-size: 12px;
      font-weight: 700;
    }
    img{
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  .right {
    background: var(--channel-back);
    border-radius: 19px;
    width: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    gap: 5px;
    div > p {
      text-align: center;
      &:nth-child(1) {
        font-size: 12px;
        font-weight: 700;
        color: white;
      }
      &:nth-child(2) {
        font-size: 8px;
        font-weight: 500;
        color: var(--channel-text);
      }
    }
  }
`;
export const StyledWeekDays = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  .item {
    border-radius: 6px;
    width: 34px;
    height: 47px;
    display: flex;
    padding-top: 5px;
    justify-content: flex-start;
    gap: 5px;
    flex-direction: column;
    align-items: center;
    font-family: "TT Travels Trl", sans-serif;
    font-weight: 300;
    font-size: 10px;
    background-color: #807faa;
    .check {
      width: 19px;
      height: 19px;
      background-color: #110055;
      border-radius: 50%;
      .checked {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2px;
      }
    }
  }
  .active {
    background: #5351a7;
  }
  .disabled{
    background: gray;
  }
`;
export const StyledGrid = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2,minmax(140px ,160px));
  justify-content: center;
  gap: 30px;
  .item {
    position: relative;
    display: flex;
    justify-content: center;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .text {
      position: absolute;
      bottom: 10px;
      font-family: "TT Travels Trl", sans-serif;
      width: max-content;
      max-width: 110px;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 700;
      font-size: 15px;
      color: white;
    }
  }
`;
