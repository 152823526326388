import { NutritionWrapper, StyledTable } from "./styled";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/pageTitle";
import { StyledGrid } from "../dashboard/styled";
import img1 from "../../images/nutrition/1.png";
import img2 from "../../images/nutrition/2.png";
import img3 from "../../images/nutrition/3.png";
import img4 from "../../images/nutrition/4.png";
import { useEffect, useState } from "react";
import FullInfo from "./fullInfo";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthProfile } from "../../store/features/auth/selectors";
import { getMyProfile } from "../../store/features/auth/slice";

const Nutrition = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const blocks = [
    { img: img1, text: "Завтрак", key: "breakfast" },
    { img: img2, text: "Обед", key: "lunch" },
    { img: img3, text: "Ужин", key: "dinner" },
    { img: img4, text: "Перекус", key: "snack" },
  ];
  const [fullInfo, setFullInfo] = useState();
  const myProfile = useSelector(selectAuthProfile());
  useEffect(() => {
    if (!myProfile) {
      dispatch(getMyProfile())
    }
  }, [dispatch, myProfile])
  const tableData = myProfile?.dci

  const floor = (value) => {
    return String(Math.floor(value))
  }

  return (
    <NutritionWrapper>
      <PageTitle
        title={fullInfo ? fullInfo.text : "Питание"}
        backFunc={fullInfo ? () => setFullInfo(null) : () => navigate(-1)}
      />
      {fullInfo ? (
        <FullInfo todayNutro={myProfile?.UserNutrition.nutrition} selected={fullInfo} />
      ) : (
        <>
          <StyledTable>
            <p className="title">
              Мой рацион
            </p>
            {tableData ?
              <div className="content">
                <div>
                  <p>Ккал</p>
                  <p>{floor(tableData?.DCI)}</p>
                </div>
                <div>
                  <p>Белки</p>
                  <p>{floor(tableData.Proteins)}</p>
                </div>
                <div>
                  <p>Жиры</p>
                  <p>{floor(tableData.Fats)}</p>
                </div>
                <div>
                  <p>Углеводы</p>
                  <p>{floor(tableData.Carbohydrates)}</p>
                </div>
              </div>
              : ''}
          </StyledTable>
          <StyledGrid style={{ marginTop: "50px" }}>
            {blocks.map((item, index) => {
              return (
                <div
                  onClick={() => setFullInfo(item)}
                  key={index}
                  className="item"
                >
                  <img key={index} src={item.img} alt="icon" />
                  <p className="text">{item.text}</p>
                </div>
              );
            })}
          </StyledGrid>
        </>
      )}
    </NutritionWrapper>
  );
};
export default Nutrition;
