import React from "react";
import { StyledWrapper } from "./styled";
import { IoIosSearch } from "react-icons/io";

const SearchInput = ({ value, onchange }) => {
  return (
    <StyledWrapper>
      <IoIosSearch className="icon" />
      <input type="text" placeholder="Поиск" onChange={onchange} value={value} />
    </StyledWrapper>
  );
};

export default SearchInput;
