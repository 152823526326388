import React, { useState } from "react";
import PageTitle from "../../components/pageTitle";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router";
import { StyledTriningData } from "./styled";
import DropDown from "./dropDown";

const TriningData = () => {
  const navigate = useNavigate();
  const [selectedItem, setSelecteditem] = useState();

  const arr = [
    {
      name: "Планка",
      id: 1,
      text: "Планка 3 минуты, 2 подхода Отжимания 40х2 Приседания 12х3 Подтягивания  15, 3 подхода Тяга веса 40, 2 подхода Берпи 15, 1 подход",
    },
    {
      name: "Планка1",
      id: 2,
    },
    {
      name: "Планка2",
      id: 3,
    },
    {
      name: "Планка3",
      id: 4,
    },
    {
      name: "Планка4",
      id: 5,
    },
    {
      name: "Планка5",
      id: 6,
    },
  ];

  return (
    <StyledTriningData>
      <PageTitle title="Данные тренировок" backFunc={() => navigate(-1)} />
      <br />
      <div className="plusBtn">
        <FiPlus />
      </div>
      <br />
      {arr.map((item) => {
        return (
          <DropDown
            onClick={() => {
              if (selectedItem === item.id) {
                setSelecteditem(null);
              } else {
                setSelecteditem(item.id);
              }
            }}
            text={item.text}
            title={item.name}
            open={item.id === selectedItem}
          />
          // <>
          //   {item.name}
          //   <FaAngleRight />
          // </>
        );
      })}
    </StyledTriningData>
  );
};

export default TriningData;
