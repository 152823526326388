import React, { useRef } from "react";
import { StyledWrapper } from "./styled";
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const SliderComp = ({ files, objKey, id }) => {
  const sliderRef = useRef(null);

  const SamplePrevArrow = ({ className, style, onClick, currentSlide }) => (
    <div
      className={className}
      style={{ ...style }}
      onClick={() => {
        if (currentSlide > 0) {
          onClick();
        }
      }}
    >
      <FaAngleLeft />
    </div>
  );

  const SampleNextArrow = ({ className, style, onClick, currentSlide, slideCount }) => (
    <div
      className={className}
      style={{ ...style }}
      onClick={() => {
        if (currentSlide < slideCount - 1) {
          onClick();
        }
      }}
    >
      <FaAngleRight />
    </div>
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <StyledWrapper>
      {files?.length ?
        <Slider ref={sliderRef} {...settings}>
          {files?.length
            ? files.map((file, index) => (
              <div key={index} className="img">
                <img src={file[objKey]} alt="" />
              </div>
            ))
            : ""}
        </Slider>
        : ''}
    </StyledWrapper>
  );
};

export default SliderComp;
