import React from "react";

const LoadingComp = ({opacity}) => {
    return (
        <div style={{opacity: opacity ? opacity : 1}} className="start-loader-container">
            <div className="loader"></div>
        </div>
    );
};

export default LoadingComp;
