import React, { useEffect, useState } from "react";
import { BanksModalContent } from "./styled";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { selectCarts } from "../../../store/features/customs/selectors";
import { getCarts } from "../../../store/features/customs/slice";
import PriceModal from "./priceModal";
import { editUser, getMyProfile } from "../../../store/features/auth/slice";
import SuccessModal from "../successModal";
import { useNavigate } from "react-router";

const BanksModal = ({ open, handleModal }) => {
  Modal.setAppElement("#root");
  const app = document.querySelector(".App");
  const isLightTheme = app?.classList?.value.includes("light");
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const carts = useSelector(selectCarts())
  const [selected, setSelected] = useState(null)
  const [showPriceModal, setShowPriceModal] = useState(null)
  const [confirmed, setConfirmed] = useState(null)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "340px",
      background: "var(--background)",
      borderRadius: "21px",
      border: "2px solid var(--text1-color)",
    },
    overlay: {
      background: "transparent",
    },
  };
  useEffect(() => {
    if (open) {
      dispatch(getCarts())
    } else {
      setSelected(null)
      setConfirmed(null)
    }
  }, [open, dispatch])
  const handlePay = () => {
    const data = { payment_id: selected?.id, subscription_time: confirmed }
    dispatch(editUser(data)).then(res => {
      if (res.payload) {
        setShowSuccessModal(true)
        setTimeout(() => {
          setShowSuccessModal(false)
          handleModal()
          dispatch(getMyProfile())
          navigate('/')
        }, 1000)

      }
    })
  }
  const formattedDescription = selected?.description.replace(/(.{4})/g, '$1 ').trim();
  return (
    <Modal
      className={isLightTheme ? "light lightModal" : ""}
      isOpen={open}
      onRequestClose={() => handleModal(false)}
      style={customStyles}
    >
      {open && (
        <IoIosClose
          className="modalCloseIcon"
          onClick={() => handleModal(false)}
        />
      )}
      <BanksModalContent>
        {selected ?
          <div className="selectedBank">
            <p className="title">
              <span className="back" onClick={() => setSelected(null)}>Назад</span>
              <span>{selected?.title}</span>
            </p>
            <br />
            <p className="title" style={{ margin: '0 0 8px 0' }}>Получатель: {selected?.name}</p>
            <p className="title">{formattedDescription}</p>
            <p className="title1">Месяцев</p>
            <div className="checkboxes">
              {[1, 2, 3, 4, 5, 6].map(item => {
                return (
                  <p className={confirmed === item ? 'active' : ''} onClick={() => setShowPriceModal(item)} key={item}>{item}</p>
                )
              })}
            </div>
            <br />
            <div className="btns">
              <button onClick={handlePay} disabled={!confirmed}>Оплачено</button>
              <button onClick={handleModal} >Отмена</button>
            </div>
          </div>
          :
          <>
            <p className="title">Выберите банк</p>
            <div className="flex">
              {carts?.rows?.map(item => {
                return (
                  <div key={item.id}>
                    <p onClick={() => setSelected(item)}>{item.title}</p>
                  </div>
                )
              })}
            </div>
          </>
        }
      </BanksModalContent>
      <SuccessModal open={showSuccessModal} handleModal={() => setShowSuccessModal(false)} />
      <PriceModal setConfirmed={setConfirmed}
        open={!!showPriceModal}
        selected={showPriceModal}
        price={+selected?.amount} handleModal={() => setShowPriceModal(null)} />
    </Modal>
  );
};

export default BanksModal;
