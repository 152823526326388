export const formatDate = (date) => {
    const formattedDate = date.toLocaleDateString('en-US');
    const [month, day, year] = formattedDate.split('/');

    // Добавляем ноль перед числом дня, если оно меньше 10
    const paddedDay = day.padStart(2, '0');
    const paddedMonth = month.padStart(2, '0');

    // Собираем дату в нужном формате
    const formatted = `${year}-${paddedMonth}-${paddedDay}`;
    return formatted;
}
export const formatDateReverse = (date) => {
    const formattedDate = date.toLocaleDateString('en-US');
    const [month, day, year] = formattedDate.split('/');

    // Добавляем ноль перед числом дня, если оно меньше 10
    const paddedDay = day.padStart(2, '0');
    const paddedMonth = month.padStart(2, '0');

    // Собираем дату в нужном формате
    const formatted = `${paddedDay}.${paddedMonth}.${year}`;
    return formatted;
}

export const _debounce = (delay = 400) => {
    let timer = null;

    return (cb) => {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(cb, delay);
    };
};


export const getTriningDaysOfWeek = (allDays) => {
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const firstDayOfWeek = new Date(currentDate);
    firstDayOfWeek.setDate(currentDate.getDate() - currentDayOfWeek + (currentDayOfWeek === 0 ? -6 : 1));
    const lastDayOfWeek = new Date(currentDate);
    lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
    const currentWeekTrainingDays = allDays?.filter(day => day >= firstDayOfWeek.getDate() && day <= lastDayOfWeek.getDate());

    return currentWeekTrainingDays

}
export const getCurrentWeekAllDays = () => {
    const arr = [];
    const today = new Date();
    const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1))).getDate();
    const lastDayOfWeek = new Date(today.setDate(today.getDate() + 6)).getDate();
    for (let day = firstDayOfWeek; day <= lastDayOfWeek; day++) {
        if (!arr.includes(day)) {
            arr.push(day);
        }
    }
    return arr;
};


export const weekDays = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
