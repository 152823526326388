import styled from "styled-components";

export const StyledFullInfo = styled.div`
    .name{
        text-align: center;
        color: var(--text1-color);
        margin-top: -15px;
        font-size: 17px;
        font-weight: 500;
        line-height: 17px;
    }
    .text{
        margin-top: 10px;
        font-size: 15px;
        font-weight: 500;
        color: var(--text1-color);
    }
    .description{
        margin-top: 30px;
        font-size: 17px;
        font-weight: 500;
        color: var(--text1-color);
    }
`;
