import { StyledImageDrop, StyledPhotoReport } from "./styled";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/pageTitle";
import { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getReportByDate, getReports } from "../../store/features/photoReport/slice";
import { selectAllReports, selectReportByDate } from "../../store/features/photoReport/selectors";
import { formatDate } from "../../helpers/helper";
import SliderComp from "../../components/slider";

const PhotoReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);

  const data = useSelector(selectAllReports())
  const byDate = useSelector(selectReportByDate())
  const [files, setFiles] = useState([])
  useEffect(() => {
    if (byDate) {
      const urls = byDate.rows.map(item => { return { file: item.url, id: item.id } });
      setFiles(urls)
    }
  }, [byDate, selected])


  const handleClick = (item) => {
    if (selected?.id === item.id) {
      setSelected(null);
      return;
    }
    setSelected(item);
  };

  useEffect(() => {
    dispatch(getReports({}))
  }, [dispatch])

  useEffect(() => {
    setFiles([])

    if (selected) {
      dispatch(getReportByDate(formatDate(new Date(selected.date))))
    }
  }, [selected, dispatch])

  return (
    <StyledPhotoReport>
      <PageTitle
        title="Фотоотчет"
        backFunc={() => navigate(-1)}
      />

      <div className="plusBtn" onClick={() => navigate('/add_photo_report')} >
        <FiPlus />
      </div>
      {data?.map((item) => {
        return (
          <StyledImageDrop
            onClick={() => handleClick(item)}
            key={item.id}
            open={item.id === selected?.id}
          >
            <div className="title">
              {new Date(item.date).toLocaleDateString()}
              <IoIosArrowDown className="arrow" />
            </div>
            <div className="drop" onClick={e => e.stopPropagation()}>
              <SliderComp id={selected?.id} objKey={'file'} files={files} />
            </div>
          </StyledImageDrop>
        );
      })}
    </StyledPhotoReport>
  );
};
export default PhotoReport;
