import { useState } from "react";
import { SelectWrapper } from "./styled";
import { IoIosArrowDown } from "react-icons/io";

const Select = ({ options, name, value, label, onchange, setFieldValue }) => {
  const [active, setActive] = useState(false);

  return (
    <SelectWrapper
      $activeLabel={value?.length > 0 || active}
      $active={active}
      onClick={() => {
        setActive(!active);
      }}
    >
      <label htmlFor={name}>{label}</label>
      <IoIosArrowDown className="arrow" />
      <div className="value">
        <p>{options?.find((i) => i.id === value)?.name}</p>
        <div className="dropDown">
          {options?.map((item, index) => {
            return (
              <div
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  onchange(item);
                  setFieldValue(name, item.id);
                  setActive(!active);
                }}
              >
                <input
                  type="radio"
                  checked={value === item.id}
                  onChange={() => {
                    setFieldValue(name, item.id);
                  }}
                />
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
    </SelectWrapper>
  );
};

export default Select;
