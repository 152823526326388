import { DashboardWrapper, StyledGrid } from "./styled";
import img1 from "../../images/newsContent/1.webp";
import img2 from "../../images/newsContent/2.webp";
import img3 from "../../images/newsContent/3.webp";
import img4 from "../../images/newsContent/4.webp";
import img5 from "../../images/newsContent/5.webp";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../components/pageTitle";

const News = () => {
  const navigate = useNavigate();
  const blocks = [
    { img: img1, text: "Обновления", url: "/custom/Updates" },
    { img: img2, text: "Интересные факты", url: "/custom/InterestingFacts" },
    { img: img3, text: "Научные исследования", url: "/custom/ScientificResearch" },
    { img: img4, text: "Работа приложения", url: "/custom/ApplicationOperation" },
    { img: img5, text: "Скидки и акции", url: "/custom/DiscountsAndPromotions" },
  ];
  const [openModal, setOpenModal] = useState(false);

  return (
    <DashboardWrapper>
      <PageTitle title="Новости" backFunc={() => navigate(-1)} />

      <StyledGrid>
        {blocks.map((item, index) => {
          return (
            <Link
              to={item.url ? item.url : "#"}
              key={index}
              className="item"
              onClick={() => setOpenModal(!openModal)}
            >
              <img key={index} src={item.img} alt="icon" />
              <p className="text">{item.text}</p>
            </Link>
          );
        })}
      </StyledGrid>
      <br />
      <br />
    </DashboardWrapper>
  );
};
export default News;
