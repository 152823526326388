import styled from "styled-components";

export const StyledHelp = styled.div`
  padding: 45px 0;
  max-width: 410px;
  margin: 0 auto;
  .title{
    font-size: 14px;
    font-weight: 300;
    color: var(--text1-color);
    a{
      text-decoration: none;
      color: var(--text1-color);
    }
  }
`;