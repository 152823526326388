import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sendRequestWithToken } from "../../../api";

const initialState = {
    loading: false,
    data: [],
    reviews: [],
    carts: [],
};

export const getReviews = createAsyncThunk(
    "custom/getReviews",
    async (_, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'POST',
                url: `/reviews/get-all`,
                data: { page: 1, limit: 100, status: "ACTIVE" }
            });

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const postReview = createAsyncThunk(
    "custom/postReview",
    async (data, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'POST',
                url: `/reviews`,
                data
            });

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const getCustom = createAsyncThunk(
    "custom/getCustom",
    async (model, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'POST',
                url: `/custom/get-all`,
                data: { model }
            });

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const getCarts = createAsyncThunk(
    "custom/getCarts",
    async (_, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'POST',
                url: `/custom/get-all`,
                data: { model: "Payment" }
            });

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);



const customsSlice = createSlice({
    name: "customs",
    initialState,
    reducers: {
        clearCustomData: (state) => {
            state.data = []
        }
    },
    extraReducers(builder) {
        builder.addCase(getCustom.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCustom.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(getCustom.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getReviews.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getReviews.fulfilled, (state, action) => {
            state.loading = false;
            state.reviews = action.payload;
        });
        builder.addCase(getReviews.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getCarts.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCarts.fulfilled, (state, action) => {
            state.loading = false;
            state.carts = action.payload;
        });
        builder.addCase(getCarts.rejected, (state) => {
            state.loading = false;
        });

    },
});

export const { clearCustomData } = customsSlice.actions
export default customsSlice.reducer;
