import React, { useEffect, useState } from "react";
import { StyledChartWrapper } from "./styled";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

const LineChart = ({ dataSource, yTitle, labels, xTitle }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        min: 0,
      },
    },
  };
  const app = document.querySelector(".App");
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!!app) {
      setLoaded(true);
    } else {
      setLoaded(false);
    }
  }, [app]);

  const isLight = app?.classList?.value.includes("light");

  useEffect(() => {
    ChartJS.defaults.scale.grid.display = false;
    ChartJS.defaults.scale.border.width = 1;
    ChartJS.defaults.color = isLight ? "black" : "white";
    ChartJS.defaults.scale.border.color = isLight ? "black" : "white";
  }, [loaded, isLight]);

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "",
        pointRadius: 0,
        data: dataSource,
        borderColor: "#0A0080",
        backgroundColor: function (context) {
          const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, 150);
          gradient.addColorStop(0, "rgba(10,0,128,1)");
          gradient.addColorStop(1, "rgba(0,8,26,0.1)");
          return gradient;
        },
      },
    ],
  };


  return (
    <StyledChartWrapper>
      {loaded && (
        <>
          <p className="yTitle">{yTitle}</p>
          <Line options={options} data={data} />
          <p className="xTitle">Кол-во {xTitle}</p>
        </>
      )}
    </StyledChartWrapper>
  );
};

export default LineChart;
