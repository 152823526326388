import styled from "styled-components";

export const StyledDropDown = styled.div`
    width: 100%;
    height: ${(props) => props.open ? 'auto' : '48px'};
    border-radius: 8px;
    border: 2px solid var(--text1-color);
    margin-bottom: 18px;
    .title{
        position: relative;
        font-size: 15px;
        font-weight: 500;
        color: var(--text1-color);
        display: flex;
        align-items: center;
        padding-left: 12px;
        height: 48px;
        .arrow{
            position: absolute;
            right: 10px;
            top: 50%;
            svg{
                transition: 0.3s;
                transform: ${(props) => props.open ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)'} ;
            }
    }
    }
    .drop{
        height: ${(props) => props.open ? 'auto' : '0'};
        padding: 10px;
        display: ${(props) => props.open ? 'block' : 'none'};
    }
    
`