import React, {useState} from "react";
import {StyledAddPage} from "./styled";
import {IoIosArrowDown} from "react-icons/io";
import {StyledImageDrop} from "../styled";
import CalendarComp from "../../../components/calendar";
import {FaAngleRight} from "react-icons/fa";
import {formatDate} from "../../../helpers/helper";
import {useDispatch} from "react-redux";
import {postReport} from "../../../store/features/photoReport/slice";
import {useNavigate} from "react-router";
import PageTitle from "../../../components/pageTitle";
import {PiQuestion} from "react-icons/pi";
import PhotoReportTextModal from "../../../components/modals/photoReportModal";
import {compressImage} from "../../../helpers/compressImage";
import LoadingComp from "../../../components/loading";

const AddPhotoReport = () => {
    const arr = [0, 1, 2, 3]
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState(new Date());

    const [prewFiles, setPrewFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleFileChange = (event, index) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile?.size / 1024 / 1024 <= 4) {
            const newPrewFiles = [...prewFiles];
            const newFiles = [...files];
            newPrewFiles[index] = URL.createObjectURL(selectedFile);
            newFiles[index] = selectedFile;
            setFiles(newFiles);
            setPrewFiles(newPrewFiles);
        }
    };

    const handlePrevClick = (item, index) => {
        if (prewFiles[item]) {
            const newPrewFiles = [...prewFiles];
            const newFiles = [...files];
            newPrewFiles[index] = '';
            newFiles[index] = '';
            setFiles(newFiles);
            setPrewFiles(newPrewFiles);
            return
        }
        document.getElementById(`fileInput${item}`).click()
    }

    const handleSave = async () => {
        setLoading(true)
        const formData = new FormData()
        if (files?.length < 4) return

        formData.append('date', formatDate(date))
        for (const file of files) {
            if (file) {
                const compressedImage = await compressImage(file);
                formData.append('file', compressedImage)
            }
        }
        dispatch(postReport(formData)).then(res => {
            setLoading(false)
            if (res.payload) {
                navigate(-1)
            }
        })
    }

    return (
        <StyledAddPage open={open}>
            {loading && <LoadingComp opacity={0.5}/>}
            <PageTitle title='Фотоотчет' backFunc={() => navigate(-1)} editFunc={() => setShowInfoModal(true)}
                       editText={<PiQuestion className="questionIcon"/>}/>
            <div className="grid">
                {arr.map((item) => {
                    return (
                        <div key={item}>
                            <div className="preview" onClick={() => handlePrevClick(item, item)}>
                                {prewFiles[item] ?
                                    <img
                                        src={prewFiles[item]}
                                        alt="Preview"
                                    /> :
                                    <p>
                                        Нажмите для выбора файла
                                    </p>}
                            </div>
                            <input
                                type="file"
                                id={`fileInput${item}`}
                                style={{display: 'none'}}
                                onChange={(e) => handleFileChange(e, item)}
                            />
                        </div>
                    )
                })}
            </div>

            <StyledImageDrop open={open}>
                <div className="title" onClick={() => setOpen(!open)}>
                    Дата
                    <IoIosArrowDown className="arrow"/>
                </div>
                <div className="drop">
                    <CalendarComp
                        onchange={(e) => {
                            setDate(e);
                        }}
                        value={date}
                    />
                </div>
            </StyledImageDrop>
            <button onClick={handleSave} disabled={files.length < 4} className="saveBtn">
                Сохранить
                <FaAngleRight className="arrow"/>
            </button>
            <PhotoReportTextModal
                text='Фото отчет вам нужен для того чтобы отслеживать свой прогресс. Для фото отчета необходимо сделать 4 фотографии. 1. Лицом руки по швам. 2. Правым боком, руки выпрямить перед собой. 3. Левым боком, руки перед собой. 4. Спиной, руки вдоль тела.'
                open={showInfoModal} handleModal={() => setShowInfoModal(false)}/>
        </StyledAddPage>
    );
};

export default AddPhotoReport;
