import { StyledProfileWrapper } from "./styled";
import { MdEdit } from "react-icons/md";
import PageTitle from "../../components/pageTitle";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuthProfile,
  selectAuthPurpose,
} from "../../store/features/auth/selectors";
import { useEffect } from "react";
import { getMyProfile, getPurpose } from "../../store/features/auth/slice";
import { Link } from "react-router-dom";
import { genders } from "../../constants";

const Profile = () => {
  const myProfile = useSelector(selectAuthProfile());
  const purposes = useSelector(selectAuthPurpose())?.rows;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyProfile());
  }, [dispatch]);
  useEffect(() => {
    if (myProfile) {
      dispatch(getPurpose(myProfile?.gender));
    }
  }, [dispatch, myProfile]);

  const navigate = useNavigate();
  return (
    <StyledProfileWrapper>
      <PageTitle title="Личные данные" backFunc={() => navigate(-1)} />
      <Link to={"/profile/update"} className="edit">
        <p>Редактировать</p>
        <MdEdit />
      </Link>
      {myProfile && (
        <div className="userInfo">
          <div className="col">
            <p>Имя:</p>
            <p>{myProfile.first_name}</p>
          </div>
          <div className="col">
            <p>Фамилия:</p>
            <p>{myProfile.last_name}</p>
          </div>
          <div className="col">
            <p>Возраст:</p>
            <p>{myProfile.age}</p>
          </div>
          <div className="col">
            <p>Вес:</p>
            <p>{myProfile.weight}</p>
          </div>
          <div className="col">
            <p>Рост:</p>
            <p>{myProfile.height}</p>
          </div>
          <div className="col">
            <p>% жира:</p>
            <p>{myProfile.fat_percent || '--'}</p>
          </div>
          <div className="col">
            <p>Количество тренировок в неделю:</p>
            <p>{myProfile.number_workouts_per_week}</p>
          </div>
          <div className="col">
            <p>Среднее количество шагов в день:</p>
            <p>{myProfile.average_number_steps_per_week}</p>
          </div>
          <div className="col">
            <p>Цель занятий:</p>
            <p>{purposes?.find((i) => i.id === myProfile.group_id)?.name}</p>
          </div>
          <div className="col">
            <p>Пол:</p>
            <p>{genders?.find((i) => i.id === myProfile.gender)?.name}</p>
          </div>
        </div>
      )}
    </StyledProfileWrapper>
  );
};

export default Profile;
