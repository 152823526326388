import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sendRequestWithToken, sendRequestWithTokenFORM } from "../../../api";

const initialState = {
    loading: false,
    allReports: [],
    reportByDate: null
};

export const getReports = createAsyncThunk(
    "reports/getReports",
    async (_, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'POST',
                url: `/file/getReports`,
                data: {
                    limit: 500
                }
            });

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const getReportByDate = createAsyncThunk(
    "reports/getReportByDate",
    async (data, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'POST',
                url: `/file/getReports`,
                data: { date: data }
            });

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const postReport = createAsyncThunk(
    "reports/postReport",
    async (data, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithTokenFORM({
                method: 'POST',
                url: `/file/photoReport`,
                data
            });

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

const reporstsSlice = createSlice({
    name: "reports",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getReports.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getReports.fulfilled, (state, action) => {
            state.loading = false;
            state.allReports = action.payload;
        });
        builder.addCase(getReports.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getReportByDate.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getReportByDate.fulfilled, (state, action) => {
            state.loading = false;
            state.reportByDate = action.payload;
        });
        builder.addCase(getReportByDate.rejected, (state) => {
            state.loading = false;
        });
    },
});

export default reporstsSlice.reducer;
