import styled, { css } from "styled-components";



export const SelectWrapper = styled.div`
  position: relative;
  label {
    position: absolute;
    left: 10px;
    top: 50%;
    font-size: 15px;
    font-weight: 500;
    transform: translateY(-45%);
    line-height: 15px;
    color: var(--text1-color);
    padding: 0 5px;
    z-index: 2;
    transition: 0.3s;
    border-radius: 0;
    transform-origin: left;
    ${(props) =>
    props.$activeLabel
      ? css`
            background: var(--background);
            transform: translateY(-45%) scale(0.8);
            top: 0;
          `
      : css``}
  }
  .arrow {
    color: var(--text1-color);
    position: absolute;
    right: 10px;
    top: 0%;
    font-size: 22px;
    font-weight: 300;
    transform: translateY(50%);
    transition: 0.3s;
    ${(props) =>
    props.$active
      ? css`
            transform: translateY(50%) rotate(180deg);
          `
      : null}
  }
  .value {
    width: 100%;
    outline: none;
    border-radius: 9px;
    border: 2px solid var(--text1-color);
    height: 48px;
    color: var(--text1-color);
    background-color: transparent;
    padding-left: 12px;
    p {
      margin-top: 12px;
      height: 21px;
    }
    .dropDown {
      margin: 15px 0 15px 0;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      gap: 8px;
      div {
        input {
          margin-right: 5px;
          background-color: transparent !important;
        }
      }
    }
    ${(props) =>
    props.$active
      ? css`
            height: auto;
          `
      : css`
            .dropDown {
              height: 0;
              display: none;
            }
          `}
  }
`;
