import styled from "styled-components";

export const StyledReview = styled.div`
    max-width: 410px;
    padding: 45px 0;
    margin: 0 auto;
    min-height: 100vh;
    .count{
        position: absolute;
        color: red;
    }
    button{
        cursor: pointer;
        background: var(--text1-color);
        color: var(--background);
        width: max-content;
        margin: 0 auto;
        font-size: 14px;
        font-weight: 700;
        display: block;
        border: none;
        border-radius: 30px;
        padding: 10px 30px;
    }
`

export const StyledReviewItem = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    border: 2px solid var(--text1-color);
    color: var(--text1-color);
    border-radius: 9px;
    /* font-size: 15px;
    line-height: 15px;
    font-weight: 500; */
    padding: 10px;
    width: 100%;
    /* height: 48px; */
    .title{
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        gap: 16px;
        font-size: 15px;
        :nth-child(1){
            font-weight: 700;
        }
        :nth-child(2){
            font-weight: 300;
        }

        .stars{
          img.filter{
            filter: brightness(0);
          }
        }
    }
    .text{
        font-size: 14px;
        font-weight: 300;
        white-space: pre-wrap;
    }
`