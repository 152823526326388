import styled from "styled-components";

export const NutritionWrapper = styled.div`
    max-width: 410px;
    padding: 45px 0;
    margin: 0 auto;
   
`;

export const StyledTable = styled.div`
        width: 100%;
        text-align: center;
        margin-top: 40px;
        .title{
            font-size: 20px;
            font-weight: 700;
            color: var(--text1-color)        
        }
        .content{
            margin-top: 30px;
            border: 2px solid var(--text1-color);
            border-radius: 15px;
            color: var(--text1-color);
            width: 100%;
            display: flex;
            div {
                flex: 1;
                flex-direction: column;
                p {
                    display: flex;
                    border: 2px solid var(--text1-color);
                    border-top: none;
                    border-left: none;
                    justify-content: center;
                    align-items: center;
                    height: 60px;
                    size: 16px;
                    font-weight: 300;
                    text-align: center;
                }

                p:last-child{
                    border-bottom: none
                }

                &:last-child{
                    p{
                        border-right: none;
                    }
                }
            }
        }
`
