import React, {useEffect} from "react";
import {StyledTechnique, StyledTechniqueItem} from "./styled";
import PageTitle from "../../components/pageTitle";
import SearchInput from "../../components/serachInput";
import {FaAngleRight} from "react-icons/fa";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {
    selectTechniques,
    selectTechniquesCount, selectTechniquesPage,
    selectTechniquesSearch
} from "../../store/features/technique/selectors";
import {clearRows, getTechniques, setPage, setSearch} from "../../store/features/technique/slice";
import {_debounce} from "../../helpers/helper";
import {getMyProfile} from "../../store/features/auth/slice";
import {selectAuthProfile} from "../../store/features/auth/selectors";

const Technique = () => {
    const debounce = _debounce(500);
    const navigate = useNavigate();
    const techniques = useSelector(selectTechniques());
    const count = useSelector(selectTechniquesCount());
    const page = useSelector(selectTechniquesPage());
    const myProfile = useSelector(selectAuthProfile());
    const search = useSelector(selectTechniquesSearch());
    const dispatch = useDispatch();
    useEffect(() => {
        if (!myProfile) {
            dispatch(getMyProfile());
        } else {
            dispatch(getTechniques());
        }
    }, [myProfile, dispatch, search, page]);
    useEffect(() => {
        return () => {
            dispatch(clearRows())
            dispatch(setPage(1))
            dispatch(setSearch(''))
        }
    }, [dispatch]);

    return (
        <StyledTechnique>
            <PageTitle
                title="Техника упражнений"
                backFunc={() => navigate(-1)}
            />
            <br/>
            <SearchInput onchange={(e) =>
                debounce(() => dispatch(setSearch(e.target.value)))
            }/>
            <br/>
            <br/>
            {techniques?.map((item) => {
                return (
                    <StyledTechniqueItem
                        onClick={() => {
                            navigate(`/technique/${item.id}`);
                        }}
                        key={item.id}
                    >
                        {item.name}
                        <FaAngleRight/>
                    </StyledTechniqueItem>
                );
            })}
            {count > techniques?.length &&
                <button className='moreBtn' onClick={() => dispatch(setPage(page + 1))}>Ещё</button>
            }
        </StyledTechnique>
    );
};

export default Technique;
