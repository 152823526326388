import styled from "styled-components";

export const ModalContent = styled.div`
  display: flex;  
  align-items: center;
  justify-content: center;
  p{
    color: var(--text1-color);
    font-size: 15px;
    font-weight: 300;
    text-align: center;
  }
`;
