import {
  DashboardWrapper,
  StyledGrid,
  StyledHeader,
  StyledWeekDays,
} from "./styled";
import checkIcon from "../../images/icons/checkIcon.png";
import img1 from "../../images/dashboardContent/1.webp";
import img2 from "../../images/dashboardContent/2.webp";
import img3 from "../../images/dashboardContent/3.webp";
import img4 from "../../images/dashboardContent/4.webp";
import img5 from "../../images/dashboardContent/5.webp";
import img6 from "../../images/dashboardContent/6.webp";
import img7 from "../../images/dashboardContent/7.webp";
import img8 from "../../images/dashboardContent/8.webp";
import img9 from "../../images/newsContent/4.webp";
import img10 from "../../images/dashboardContent/10.webp";
import img11 from "../../images/dashboardContent/11.webp";
import tgIcon from "../../images/dashboardContent/telegram.png";
import maleIcon from "../../images/icons/male.jpg";
import femaleIcon from "../../images/icons/female.jpg";
import { FaAngleRight } from "react-icons/fa";
import SubscriptionModal from "../../components/modals/subscriptionModal";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMyProfile } from "../../store/features/auth/slice";
import { selectAuthProfile } from "../../store/features/auth/selectors";
import { getCurrentWeekAllDays, getTriningDaysOfWeek, weekDays } from "../../helpers/helper";
import axios from "axios";
const REACT_APP_TELEGRAM_BOT_TOKEN = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const blocks = [
    { img: img1, text: "Мои данные", url: "/profile", subscribe: false },
    { img: img2, text: "Тренировки", url: "/workout", subscribe: true },
    { img: img3, text: "Мой прогресс", url: "/my_progress", subscribe: false },
    { img: img4, text: "Питание", url: "/nutrition", subscribe: true },
    { img: img5, text: "Новости", url: "/news", subscribe: false },
    { img: img6, text: "Загрузить фотоотчет", url: "/photo_report", subscribe: true },
    { img: img7, text: "Подписка", url: "/subsctibe", subscribe: false },
    { img: img8, text: "Техника упражнений", url: "/technique", subscribe: true },
    { img: img9, text: "Работа приложения", url: "/custom/ApplicationOperation", subscribe: false },
    { img: img10, text: "Отзывы", url: "/reviews", subscribe: false },
    { img: img11, text: "Помощь", url: "/help", subscribe: false },
  ];
  const [openModal, setOpenModal] = useState(false);
  const myProfile = useSelector(selectAuthProfile());

  useEffect(() => {
    dispatch(getMyProfile());
  }, [dispatch]);

  const [profileImage, setProfileImage] = useState('');
  const tg = window.Telegram.WebApp;
  const userId = tg.initDataUnsafe?.user?.id;

  useEffect(() => {
    const fetch = async () => {
      if (userId) {
        try {
          const response = await axios.get(`https://api.telegram.org/bot${REACT_APP_TELEGRAM_BOT_TOKEN}/getUserProfilePhotos?user_id=${userId}`);
          const photo_url = response.data.result.photos[0][0].file_id;
          const fileResponse = await axios.get(`https://api.telegram.org/bot${REACT_APP_TELEGRAM_BOT_TOKEN}/getFile?file_id=${photo_url}`);
          const filePath = fileResponse.data.result.file_path;
          const profileImageUrl = `https://api.telegram.org/file/bot${REACT_APP_TELEGRAM_BOT_TOKEN}/${filePath}`;
          setProfileImage(profileImageUrl)
        } catch (err) {
          if (myProfile && myProfile?.gender === 'MALE') {
            setProfileImage(maleIcon)
          } else {
            setProfileImage(femaleIcon)
          }
        }
      }
    }
    fetch()
  }, [userId, myProfile?.gender, myProfile]);

  return (
    <DashboardWrapper>
      <StyledHeader>
        <Link to={"/profile"} className="left">
          <img src={profileImage} alt="" />
          <p>{myProfile?.first_name + " " + myProfile?.last_name}</p>
          <FaAngleRight />
        </Link>
        <Link to='https://t.me/top_g_fitness' className="right">
          <img src={tgIcon} alt="" />
          <div>
            <p>@top_g_fitness</p>
            <p>Наш телеграмм-канал</p>
          </div>
        </Link>
      </StyledHeader>
      {myProfile?.UserExercise?.all_days?.length ?
        <StyledWeekDays>
          {getCurrentWeekAllDays()?.map((item, index) => {
            const active = getTriningDaysOfWeek(myProfile?.UserExercise?.all_days)?.includes(item)
            const checked = myProfile?.UserExercise?.completed_days?.includes(item)
            return (
              <div key={item} className={`item ${active ? "active" : ""}`}>
                {weekDays[index]}
                {active && (
                  <div className="check">
                    {checked && (
                      <div className="checked">
                        <img src={checkIcon} alt="dashIcon" />
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </StyledWeekDays>
        : ''}
      <StyledGrid>
        {blocks.map((item, index) => {
          const subscribed = myProfile?.subscription_status === 'ACTIVE' || myProfile?.subscription_status === 'ACTIVE_PENDING'
          const can = (item.subscribe && subscribed) || !item.subscribe;
          return (
            <div
              key={index}
              className="item"
              onClick={() => {
                if (can) {
                  navigate(item.url)
                } else {
                  setOpenModal(!openModal)
                }
              }}
            >
              <img key={index} src={item.img} alt="icon" />
              <p className="text">{item.text}</p>
            </div>
          );
        })}
      </StyledGrid>
      <br />
      <br />
      <div className="creator">
        <Link
          to='https://t.me/maybeDeveloper'
          target='blank'
        >Сделано @maybeDeveloper</Link>
      </div>
      <SubscriptionModal open={openModal} handleModal={setOpenModal} />
    </DashboardWrapper>
  );
};
export default Dashboard;
