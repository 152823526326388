import styled from "styled-components";

export const MyDataWrapper = styled.div`
    max-width: 410px;
    padding: 45px 0;
    margin: 0 auto;
    a{
        text-decoration: none;
        width: 200px;
        height: 50px;
        margin: 0 auto;
        border-radius: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: var(--text1-color);
        border: 2px solid var(--text1-color);
        font-size: 17px;
        font-weight: 500;
    }
`;

