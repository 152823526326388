import React from 'react'

const Star = ({ type, color, onclick }) => {
    return (
        <>
            {type === 'empty' ?
                <svg onClick={onclick} width="29" height="27" viewBox="0 0 29 27" fill='none' xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.30263 25.972L8.38913 17.132L8.45941 16.8342L8.22625 16.6361L1.23876 10.6991L10.4824 9.91267L10.7806 9.8873L10.8991 9.61247L14.5 1.26263L18.1009 9.61247L18.2194 9.8873L18.5176 9.91267L27.7612 10.6991L20.7738 16.6361L20.5406 16.8342L20.6109 17.132L22.6974 25.972L14.7545 21.2762L14.5 21.1257L14.2455 21.2762L6.30263 25.972Z" stroke={color} />
                </svg>
                :
                <svg onClick={onclick} width="29" height="27" viewBox="0 0 29 27" fill={color} xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.30263 25.972L8.38913 17.132L8.45941 16.8342L8.22625 16.6361L1.23876 10.6991L10.4824 9.91267L10.7806 9.8873L10.8991 9.61247L14.5 1.26263L18.1009 9.61247L18.2194 9.8873L18.5176 9.91267L27.7612 10.6991L20.7738 16.6361L20.5406 16.8342L20.6109 17.132L22.6974 25.972L14.7545 21.2762L14.5 21.1257L14.2455 21.2762L6.30263 25.972Z" fill={color} stroke={color} />
                </svg>
            }
        </>
    )
}

export default Star