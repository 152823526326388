import { useState } from "react";
import { DropDownWrapper } from "./styled";
import { IoIosArrowDown } from "react-icons/io";

const ExerciseComment = ({ text }) => {
    const [active, setActive] = useState(false);

    return (<DropDownWrapper
        $active={active}
    >
        <div className="title" onClick={() => {
            setActive(!active);
        }}>
            <label>Комментарии к тренировке</label>
            <IoIosArrowDown className="arrow" />
        </div>
        <pre className="text">{text}</pre>
    </DropDownWrapper>);
};

export default ExerciseComment;
