import styled from "styled-components";

export const SuccessModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .title{
    font-size: 18px;
    color: var(--text1-color);
    margin: 10px auto 0 auto ;
  }
  .img{
    margin: 0 auto;
  }
  
`;
