import React from "react";
import { StyledFullInfo } from "./styled";

const FullInfo = ({ todayNutro, selected }) => {
  return (
    <StyledFullInfo>
      {todayNutro &&
        <pre className="description">{todayNutro[selected?.key]}</pre>
      }
    </StyledFullInfo>
  );
};

export default FullInfo;
