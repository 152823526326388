import styled from "styled-components";

export const StyledProfileWrapper = styled.div`
    max-width: 410px;
    padding: 45px 0;
    margin: 0 auto;
  p {
    color: var(--text1-color);
  }
  .edit {
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
    text-decoration: none;
    p {
      font-size: 13px;
      font-weight: 500;
      text-decoration: underline;
      text-underline-offset: 2px;
    }
    svg {
      fill: var(--text1-color);
    }
  }
  .userInfo {
    font-size: 15px;
    font-weight: 500;
    margin-top: 50px;
    .col {
      display: flex;
      gap: 35px;
      align-items: flex-start;
      margin-bottom: 20px;
      p:nth-child(1) {
        max-width: 180px;
      }
    }
  }
`;
