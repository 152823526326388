import { StyledHelp } from "./styled";
import PageTitle from "../../components/pageTitle";
import { useNavigate } from "react-router";
import DropDown from "../../components/dropDown";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustom } from "../../store/features/customs/slice";
import { selectData } from "../../store/features/customs/selectors";
import { Link } from "react-router-dom";

const Help = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(selectData());
  useEffect(() => {
    dispatch(getCustom("QuestionAnswer"))
  }, [dispatch])

  return (
    <StyledHelp>
      <PageTitle title="Помощь" backFunc={() => navigate(-1)} />
      <div className="title">Не нашли ответ на Ваш вопрос? Свяжитесь с нашим менеджером <Link to='https://t.me/glebshashkov'>@glebshashkov</Link> </div>
      {data?.rows?.map(item => {
        return (
          <DropDown key={item.id} label={item.title} text={item?.description} />
        )
      })}
    </StyledHelp>
  );
};
export default Help;
