import styled from "styled-components";

export const StyledCalendarWrapper = styled.div`
    border: 2px solid var(--text1-color);
    margin: 0 auto 30px auto;
    border-radius: 8px;
    background-color: var(--calendar-back);
    padding: 0px 8px 20px 8px;
    .calendar{
        margin: 0 auto;
        .react-calendar__navigation{
            display: flex;
            justify-content: center;
            button{
                background: transparent
            }
            .react-calendar__navigation__arrow{
                display: none;
            }
            .react-calendar__navigation__label{
                color: var(--text1-color);
                border: none;
                margin: 20px auto;
                font-weight: 500;
                font-size: 15px;
                text-transform: capitalize;
            }
        }
        .react-calendar__month-view__weekdays{
            display: none !important;
        }
       
        .react-calendar__month-view__days{
            display: grid !important;
            gap: 10px;
            grid-template-columns: repeat(7,1fr);
        }
        .item,.disabled{
            margin: 0 !important;
            background: transparent;
            color: var(--text1-color);
            font-weight: 500;
            font-size: 15px;
            max-width: 35px;
            border: 1px solid var(--text1-color);
            outline: none;
            width: 35px;
            height: 35px;
            border-radius: 50%;
        }
        .disabled{
            opacity: 0.2;
            background-color: #d1d2d3 !important;
        }
        .react-calendar__year-view__months{
            display: grid !important;
            gap: 10px;
            grid-template-columns: repeat(4,1fr);
            .item{
                text-transform: capitalize;
                max-width: 85px;
                width: 100%;
                border: 1px solid var(--text1-color);
                outline: none;
                height: 35px;
                border-radius: 10px;
        }
        }
        .react-calendar__tile--active{
            background-color: var(--calendar-active-item)
        }
    }
`