import styled, { css } from "styled-components";
import { InputWrapper } from "../../../components/input/styled";

export const StyledYourWorkout = styled.div`
    max-width: 410px;
    padding: 45px 0;
    margin: 0 auto;
    min-height: 100vh;
    .headerDate{
        text-align: center;
        margin-top: -15px;
        font-size: 15px;
        font-weight: 500;
        color: var(--text1-color);
        margin-bottom: 15px;
    }
`
export const StyledContent = styled.div`
    border: 2px solid var(--text1-color);
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 20px;
    .inputs{
        gap: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
    }
    .number{
        padding-left: 5px;
        margin-bottom: 10px;
    }
    .flex{
        display: flex;
        justify-content: space-between;
    }
    .icon{
        margin-top: 5px;
        font-size: 22px;
        color: var(--text1-color);
    }
    ${InputWrapper}{
        height: 33px;
        input{
            height: 33px;
        }
        label{
            ${props =>
        props.$active &&
        css`
                    background: var(--background);
                    transform: translateY(-45%) scale(0.8);
                    top: 0;
                `}
        }
    }
`


export const StyledButton = styled.button`
    margin: 35px auto 0 auto;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 154px;
    height: 48px;
    border-radius: 30px;
    border: 2px solid var(--text1-color);
    color: var(--text1-color);
    font-size: 16px;
    font-weight: 700;
    svg{
        font-size: 18px;
    }
    &:disabled{
        color: gray;
        border-color: gray;
    }
`
