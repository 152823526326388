import { UpdateWrapper } from "./styled";
import PageTitle from "../../../components/pageTitle";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuthProfile,
  selectAuthPurpose,
} from "../../../store/features/auth/selectors";
import { useEffect, useState } from "react";
import {
  editUser,
  getMyProfile,
  getPurpose,
} from "../../../store/features/auth/slice";
import { Formik } from "formik";
import Input from "../../../components/input";
import Select from "../../../components/select";
import { genders } from "../../../constants";

const ProfileUpdate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [initialState, setInitialState] = useState({
    first_name: "",
    last_name: "",
    age: "",
    height: "",
    weight: "",
    fat_percent: null,
    number_workouts_per_week: 0,
    average_number_steps_per_week: 0,
    group_id: "",
    gender: "",
  });

  const myProfile = useSelector(selectAuthProfile());
  const purposes = useSelector(selectAuthPurpose())?.rows;
  useEffect(() => {
    if (myProfile) {
      setInitialState({
        first_name: myProfile.first_name,
        last_name: myProfile.last_name,
        age: myProfile.age,
        height: myProfile.height,
        weight: myProfile.weight,
        fat_percent: myProfile.fat_percent,
        number_workouts_per_week: myProfile.number_workouts_per_week,
        average_number_steps_per_week: myProfile.average_number_steps_per_week,
        group_id: myProfile.group_id,
        gender: myProfile.gender,
      });
      dispatch(getPurpose(myProfile.gender));
    }
  }, [dispatch, myProfile]);

  const handleSubmitForm = async (values) => {
    if (!values.fat_percent || !values.fat_percent.length) {
      values.fat_percent = null
    }

    dispatch(editUser(values)).then(res => {
      if (res.payload) {
        navigate(-1)
      }
    });
  };

  useEffect(() => {
    dispatch(getMyProfile());
  }, [dispatch]);

  return (
    <UpdateWrapper>
      <PageTitle title="Личные данные" backFunc={() => navigate(-1)} />
      <Formik
        initialValues={initialState}
        enableReinitialize
        onSubmit={handleSubmitForm}
      >
        {(formik) => {
          const { setFieldValue, values, handleChange, handleSubmit } = formik;

          return (
            <form onSubmit={handleSubmit} className="registrForm">
              <Input
                type="text"
                value={values.first_name}
                label="Имя"
                onchange={handleChange}
                name="first_name"
              />
              <Input
                type="text"
                value={values.last_name}
                label="Фамилия"
                onchange={handleChange}
                name="last_name"
              />
              <Input
                type="text"
                value={String(values.age)}
                inputType='numeric'
                label="Возраст"
                onchange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleChange(e);
                  }
                }}
                name="age"
              />
              <Input
                type="text"
                inputType='numeric'
                value={String(values.height)}
                label="Рост"
                onchange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleChange(e);
                  }
                }}
                name="height"
              />
              <Input
                type="text"
                value={String(values.weight)}
                inputType='numeric'
                label="Вес"
                onchange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleChange(e);
                  }
                }}
                name="weight"
              />
              <Input
                type="text"
                value={values.fat_percent ? String(values.fat_percent) : ''}
                inputType='numeric'
                label="% жира"
                onchange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleChange(e);
                  }
                }}
                name="fat_percent"
              />
              <Input
                type="text"
                inputType='numeric'
                value={
                  values.number_workouts_per_week
                    ? String(values.number_workouts_per_week)
                    : ""
                }
                label="Количество тренировок в неделю"
                onchange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleChange(e);
                  }
                }}
                name="number_workouts_per_week"
              />
              <Input
                type="text"
                inputType='numeric'
                value={
                  values.average_number_steps_per_week
                    ? String(values.average_number_steps_per_week)
                    : ""
                }
                label="Среднее количество шагов в день"
                onchange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleChange(e);
                  }
                }}
                name="average_number_steps_per_week"
              />
              <Select
                options={genders}
                value={values.gender}
                label="Пол"
                onchange={(e) => {
                  dispatch(getPurpose(e.id));
                }}
                name="gender"
                setFieldValue={setFieldValue}
              />
              <Select
                options={purposes?.filter(i => i.gender !== 'UNKNOWN')}
                value={values.group_id}
                label="Цель занятий"
                onchange={(e) => { }}
                name="group_id"
                setFieldValue={setFieldValue}
              />
              <button type="submit">Продолжить</button>
            </form>
          );
        }}
      </Formik>
    </UpdateWrapper>
  );
};

export default ProfileUpdate;
