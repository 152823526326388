import styled from "styled-components";

export const StyledWorkout = styled.div`
    max-width: 410px;
    padding: 45px 0;
    margin: 0 auto;
    min-height: 100vh;
    
  .react-calendar__tile {
    position: relative;
  }
`