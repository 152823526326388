import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/auth/slice";
import techniqueSlice from "./features/technique/slice";
import exerciseSlice from "./features/exercise/slice";
import reporstsSlice from "./features/photoReport/slice";
import customsSlice from "./features/customs/slice";

export const store = configureStore({
    reducer: {
        auth: authSlice,
        technique: techniqueSlice,
        exercise: exerciseSlice,
        reports: reporstsSlice,
        customs: customsSlice
    },
});
