import React from "react";
import { StyledDropDown } from "./styled";
import { FaAngleDown } from "react-icons/fa";

const DropDown = ({ open, title, text, onClick }) => {
  return (
    <StyledDropDown open={open} onClick={onClick}>
      <div className="title">
        {title}
        <div className="arrow">
          <FaAngleDown />
        </div>
      </div>
      <div className="drop">{text}</div>
    </StyledDropDown>
  );
};

export default DropDown;
