import {useState} from "react";
import {DropDownWrapper} from "./styled";
import {IoIosArrowDown} from "react-icons/io";
import SliderComp from "../slider";

const DropDown = ({text, label, files}) => {
    const [active, setActive] = useState(false);

    return (
        <DropDownWrapper
            $active={active}
        >
            <div className="title" onClick={() => {
                setActive(!active);
            }}>
                <label>{label}</label>
                <IoIosArrowDown className="arrow"/>
            </div>
            <div className="text">
                <pre>{text}</pre>
                {files?.length ?
                    <>
                        <br/><br/>
                        <SliderComp objKey={'url'} files={files}/>
                    </>
                    : ''}
                <br/>
            </div>
        </DropDownWrapper>
    );
};

export default DropDown;
