import { MyDataWrapper } from "./styled";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../components/pageTitle";
import LineChart from "../../components/lineChart";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWeightData, getWorkingWeights } from "../../store/features/auth/slice";
import { selectAuthWeightData, selectAuthWorkingWeight } from "../../store/features/auth/selectors";

const MyProgress = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const weightData = useSelector(selectAuthWeightData())
  const workingWeightData = useSelector(selectAuthWorkingWeight())

  useEffect(() => {
    dispatch(getWeightData())
    dispatch(getWorkingWeights())
  }, [dispatch])

  return (
    <MyDataWrapper>
      <PageTitle title="Мой прогресс" backFunc={() => navigate(-1)} />

      <br />
      <br />
      {weightData?.weights ?
        <LineChart
          labels={Object.keys(weightData?.weights)}
          dataSource={Object.values(weightData?.weights)}
          yTitle="Вес/кг"
          xTitle="дней"
        />
        : ""}
      <br />
      <br />
      <br />
      {workingWeightData?.userExercise ?
        <LineChart
          labels={Object.keys(workingWeightData.userExercise)}
          dataSource={Object.values(workingWeightData.userExercise).map(item => item / 1000)}
          yTitle="Тоннаж/тонна"
          xTitle="недель"
        />
        : ""}
      <br />
      <br />
      <br />

      <Link to="/add_progress">Добавить данные</Link>
    </MyDataWrapper>
  );
};
export default MyProgress;
