import styled from "styled-components";

export const PriceModalContent = styled.div`
  position: relative;
  .title{
    width: 100%;
    margin: 35px 0;
    font-size: 15px;
    color: var(--text1-color);
    text-align: center;
  }
  .btns{
      display: flex;
      justify-content: center;
      gap: 24px;
      button{
        background: transparent;
        padding: 5px 8px;
        color: var(--text1-color);
        border: 1px solid var(--text1-color);
        border-radius: 15px;
      }
    }
  
`;
