export const genders = [
    { name: "Мужской ", id: "MALE" },
    { name: "Женский", id: "FEMALE" },
];

export const weekDays = [
    'Пн',
    'Вт',
    'Ср',
    'Чт',
    'Пт',
    'Сб',
    'Вс',
]