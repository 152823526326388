import {MdKeyboardArrowLeft} from "react-icons/md";
import {StyledPageTitle} from "./styled";

const PageTitle = ({title, backFunc, editFunc, editText, editIcon}) => {
    return (<StyledPageTitle>
        <p className="back" onClick={backFunc}>
            <MdKeyboardArrowLeft/>
            назад
        </p>
        <p className="title">{title}</p>
        <p className={`${editIcon  ? 'underline edit' : 'edit'}`} onClick={() => {
            if (editIcon === '') return
            editFunc()
        }}>
            {editText && editText}
            {editIcon && editIcon}
        </p>
    </StyledPageTitle>);
};

export default PageTitle;
