import styled from "styled-components";

export const SubscribeWrapper = styled.div`
    max-width: 410px;
    padding: 45px 0;
    margin: 0 auto;
  .text{
    color: var(--text1-color);
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-top: 40px;
  }
  button{
    border: 2px solid var(--text1-color);
    background-color: transparent;
    width: 177px;
    height: 46px;
    margin: 0 auto;
    border-radius: 30px;
    margin-top: 60px;
    display: block;
    color: var(--text1-color);
    font-size: 15px;
    font-weight: 500;

  }
   .questionIcon{
        font-size: 24px;
    }
`;
