import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sendRequestWithToken } from "../../../api";

const initialState = {
    loading: false,
    startLoading: true,
    purpose: null,
    profile: null,
    weightData: null,
    workingWeights: null
};

export const getPurpose = createAsyncThunk(
    "auth/getPurpose",
    async (type, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'GET',
                url: `/group/get-all/${type}`,
            });

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const onRegister = createAsyncThunk(
    "auth/onLogin",
    async (data, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'POST',
                url: '/auth/registration',
                data
            });
            if (!response.payload) {
                alert('message', response.message)
                alert('data', response.data?.message)
            }

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const getMyProfile = createAsyncThunk(
    "auth/getMyPrifle",
    async (_, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'GET',
                url: '/auth/getMyProfile',
            });
            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const getUserExist = createAsyncThunk(
    "auth/getUserExist",
    async (_, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'GET',
                url: '/auth/getMyProfile',
            });
            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const editUser = createAsyncThunk(
    "auth/editUser",
    async (data, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'POST',
                url: '/auth/updateMyProfile',
                data
            });

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const getWeightData = createAsyncThunk(
    "auth/getWeightData",
    async (_, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'GET',
                url: '/auth/getWeight',
            });
            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const addWeightData = createAsyncThunk(
    "auth/addWeightData",
    async (data, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'POST',
                url: '/auth/addWeight',
                data
            });

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const getWorkingWeights = createAsyncThunk(
    "auth/getWorkingWeights",
    async (_, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'GET',
                url: '/exercise/getWorkingWeights',
            });

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);



const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(onRegister.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(onRegister.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(onRegister.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getPurpose.pending, (state) => {
            state.loading = false;
        });
        builder.addCase(getPurpose.fulfilled, (state, action) => {
            state.loading = false;
            state.purpose = action.payload
        });
        builder.addCase(getPurpose.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getMyProfile.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getMyProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.profile = action.payload
        });
        builder.addCase(getMyProfile.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(editUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editUser.fulfilled, (state, action) => {
            state.loading = false;
            state.profile = action.payload
        });
        builder.addCase(editUser.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getWeightData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getWeightData.fulfilled, (state, action) => {
            state.loading = false;
            state.weightData = action.payload
        });
        builder.addCase(getWeightData.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getWorkingWeights.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getWorkingWeights.fulfilled, (state, action) => {
            state.loading = false;
            state.workingWeights = action.payload
        });
        builder.addCase(getWorkingWeights.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getUserExist.pending, (state) => {
            state.startLoading = true;
        });
        builder.addCase(getUserExist.fulfilled, (state) => {
            state.startLoading = false;
        });
        builder.addCase(getUserExist.rejected, (state) => {
            state.startLoading = false;
        });
    },
});

export default authSlice.reducer;
