import React, { useEffect } from "react";
import { StyledFullInfo } from "./styled";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectTechniqueByID } from "../../../store/features/technique/selectors";
import { getTechniqueById } from "../../../store/features/technique/slice";
import PageTitle from "../../../components/pageTitle";

const FullInfo = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const data = useSelector(selectTechniqueByID())

  useEffect(() => {
    dispatch(getTechniqueById(params.id))
  }, [dispatch, params.id])

  return (
    <StyledFullInfo>
      <PageTitle
        title="Техника упражнений"
        backFunc={() => navigate(-1)}
      />
      <br />
      <div className="container">
        <p className="name">{data?.name}</p>
        <pre className="text">{data?.description}</pre>
        {data?.video_url.includes('iframe') ?
          <div dangerouslySetInnerHTML={{ __html: data?.video_url }}></div>
          : ''}
      </div>

    </StyledFullInfo>
  );
};

export default FullInfo;
