import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sendRequestWithToken } from "../../../api";
import { getMyProfile } from "../auth/slice";

const initialState = {
    loading: false,
    exerciseByDay: null
};


export const finishExercise = createAsyncThunk(
    "exercise/finishExercise",
    async (day, { rejectWithValue, dispatch }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'POST',
                url: `/exercise/finishUserExercise`,
                data: { day: +day }
            });
            if (response.payload) {
                dispatch(getMyProfile())
            }

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const getExerciseWithDay = createAsyncThunk(
    "exercise/getExerciseWithDay",
    async (day, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'POST',
                url: `/exercise/getUserExerciseWithDay`,
                data: {
                    day: +day
                }
            });

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const editExercise = createAsyncThunk(
    "exercise/editExercise",
    async (data, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'POST',
                url: `/exercise/addUserExercise`,
                data
            });

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const changeExerciesDay = createAsyncThunk(
    "exercise/changeExerciesDay",
    async (data, { rejectWithValue }) => {
        try {
            const response = await sendRequestWithToken({
                method: 'PATCH',
                url: `/exercise/updateUserExerciseDay`,
                data
            });

            return response.payload;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

const exerciseSlice = createSlice({
    name: "exercise",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(finishExercise.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(finishExercise.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(finishExercise.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getExerciseWithDay.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getExerciseWithDay.fulfilled, (state, action) => {
            state.loading = false;
            state.exerciseByDay = action.payload
        });
        builder.addCase(getExerciseWithDay.rejected, (state) => {
            state.loading = false;
        });

    },
});

export default exerciseSlice.reducer;
