import React, { useState } from "react";
import { BanksModalContent } from "./styled";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";
import CalendarComp from "../../calendar";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { changeExerciesDay } from "../../../store/features/exercise/slice";
import { getMyProfile } from "../../../store/features/auth/slice";
// import { changeExerciesDay } from "../../../store/features/exercises/slice";
// import { getOneUser } from "../../../store/features/auth/slice";

const CalendarModal = ({ open, handleModal, user_id, allDays }) => {
  Modal.setAppElement("#root");
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const app = document.querySelector(".App");
  const isLightTheme = app?.classList?.value.includes("light");
  const [date, setDate] = useState(new Date())

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      zIndex: 7,
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "340px",
      background: "var(--background)",
      borderRadius: "21px",
      border: "2px solid var(--text1-color)",
    },
    overlay: {
      background: "transparent",
    },
  };
  const handleSave = () => {
    const new_day = date.getDate()
    const old_day = +params.id
    const data = {
      user_id,
      old_day,
      new_day
    }
    dispatch(changeExerciesDay(data)).then(res => {
      if (res.payload) {
        handleModal()
        navigate(-1)
        dispatch(getMyProfile())
      }
    })
  }

  return (
    <Modal
      className={isLightTheme ? "light lightModal" : ""}
      isOpen={open}
      onRequestClose={() => handleModal(false)}
      style={customStyles}
    >
      {open && (
        <IoIosClose
          className="modalCloseIcon"
          onClick={() => handleModal(false)}
        />
      )}
      <BanksModalContent>
        <div className="title">Выберите день</div>
        <CalendarComp value={date} onchange={e => setDate(e)} />
        <button
          disabled={date.getDate() === +params.id || allDays?.includes(+date.getDate())}
          className="save"
          onClick={handleSave}
        >
          Сохранить
        </button>
      </BanksModalContent>
    </Modal>
  );
};

export default CalendarModal;
