export const selectReportsLoading = () => (state) => state.reports.loading;
export const selectAllReports = () => (state) => {
    const data = state.reports.allReports.rows;
    const uniqueData = data?.filter((obj, index, self) => {
        return index === self.findIndex((t) => (
            t.date === obj.date
        ));
    });
    return uniqueData
}



export const selectAllReportsCount = () => (state) => state.reports.allReports.count;
export const selectReportByDate = () => (state) => state.reports.reportByDate;
