import styled, { css } from "styled-components";

export const InputWrapper = styled.div`
  position: relative;
  label {
    position: absolute;
    left: 10px;
    top: 50%;
    font-size: 16px;
    font-weight: 500;
    transform: translateY(-45%);
    line-height: 15px;
    color: var(--text1-color);
    padding: 0 5px;
    z-index: 2;
    transition: 0.3s;
    border-radius: 0;
    transform-origin: left;
    ${props =>
    props.$active &&
    css`
        background: var(--background);
        transform: translateY(-45%) scale(0.8);
        top: 0;
      `}
  }
  input {
    width: 100%;
    outline: none;
    border-radius: 9px;
    border: 2px solid var(--text1-color);
    height: 48px;
    color: var(--text1-color);
    background-color: transparent;
    font-size: 16px;
    padding-left: 12px;
    &::placeholder {
      border: 2px solid var(--text1-color);
    }
    &:focus {
      color: var(--text1-color);
      font-weight: 500;
    }
    &:-webkit-autofill {
      color: var(--text1-color) !important; /* Цвет текста */
      background-color: transparent !important; /* Цвет фона */
    }

    /* Стили для предупреждения о заполнении */
    &:-webkit-autofill::first-line {
      font-size: 16px; /* Размер текста */
    }
  }
`;
