import { StyledReview, StyledReviewItem } from "./styled";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/pageTitle";
import { getReviews } from "../../store/features/customs/slice";
import { useDispatch, useSelector } from "react-redux";
import { selectReviews } from "../../store/features/customs/selectors";
import ReviewBlockModal from "../../components/modals/reviewBlockModal";
import { selectAuthProfile } from "../../store/features/auth/selectors";
import { getMyProfile } from "../../store/features/auth/slice";

const Reviews = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(selectReviews())
  const [showModal, setShowModal] = useState(false)
  const myProfile = useSelector(selectAuthProfile())

  useEffect(() => {
    dispatch(getReviews())
  }, [dispatch])

  useEffect(() => {
    if (!myProfile) {
      dispatch(getMyProfile())
    }
  }, [myProfile, dispatch])

  return (
    <StyledReview>
      <PageTitle title="Отзывы" backFunc={() => navigate(-1)} />
      <br />
      <button onClick={() => {
        if (myProfile?.subscribe) {
          navigate('/addReview')
        } else {
          setShowModal(true)
        }
      }}>Оставить отзыв</button>
      <br />
      {data?.rows?.map(item => {
        return (
          <StyledReviewItem key={item.id}>
            <div className="title">
              <p>
                {item?.user?.first_name + ' ' + item?.user?.last_name}
              </p>
              <p className="stars">
                {[...Array(item.stars)].map((_, index) => (
                  <svg key={index} width="12" height="11" viewBox="0 0 12 11" fill="var(--text1-color)" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.295 11L3.27 6.93289L0 4.19737L4.32 3.83553L6 0L7.68 3.83553L12 4.19737L8.73 6.93289L9.705 11L6 8.84342L2.295 11Z" fill="var(--text1-color)" />
                  </svg>
                ))}
              </p>
            </div>
            <p className="text">{item?.description}</p>
          </StyledReviewItem>
        )
      })}
      <br />
      <br />
      <ReviewBlockModal open={showModal} handleModal={() => setShowModal(false)} />
    </StyledReview>
  );
};
export default Reviews;
