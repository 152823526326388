import { AddDataWrapper } from "./styled";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../../components/pageTitle";
import { FaAngleRight } from "react-icons/fa";
import { StyledCalendarWrapper } from "../../../components/calendar/styled";
import Calendar from "react-calendar";
import { useState } from "react";
import Input from "../../../components/input";
import { useDispatch } from "react-redux";
import { addWeightData } from "../../../store/features/auth/slice";
import PhotoReportTextModal from "../../../components/modals/photoReportModal";
import { PiQuestion } from "react-icons/pi";

const AddProgress = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const [weight, setWeight] = useState();
  const [showInfoModal, setShowInfoModal] = useState(false);

  const handleSave = () => {
    const data = {
      weight, day: date.getDate()
    }
    dispatch(addWeightData(data)).then(res => {
      if (res.payload) {
        navigate(-1)
      }
    })
  }
  return (
    <AddDataWrapper>
      <PageTitle title="Добавить данные" backFunc={() => navigate(-1)} editFunc={() => setShowInfoModal(true)} editText={<PiQuestion className="questionIcon" />} />
      <StyledCalendarWrapper>
        <Calendar
          onChange={(e) => {
            setDate(e);
          }}
          value={date}
          className="calendar"
          nextLabel={false}
          next2Label=""
          prev2Label=""
          prevLabel=""
          navi
          navigationLabel={({ label }) => label.split(" ")[0]}
          minDetail="year"
          showNeighboringMonthDays={false}
          locale="ru"
          tileClassName={"item"}
        />
      </StyledCalendarWrapper>
      <Input
        label="Вес"
        value={weight}
        onchange={(e) => setWeight(e.target.value.replace(/\D/g, ''))}
      />
      <br />
      <br />
      <button
        onClick={handleSave}
      >
        Сохранить
        <FaAngleRight />
      </button>

      <PhotoReportTextModal text={'Сюда Вы вписываете ваш текущий вес. Нужно взвешиваться раз в неделю с утра натощак, чтобы отслеживать ваш прогресс и делать корректировки по питанию и по программе тренировок.'} open={showInfoModal} handleModal={() => setShowInfoModal(false)} />
    </AddDataWrapper>
  );
};
export default AddProgress;
