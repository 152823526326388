import styled from "styled-components";

export const BanksModalContent = styled.div`
  position: relative;
  .title{
    width: 100%;
    margin-bottom: 35px;
    font-size: 15px;
    color: var(--text1-color);
    text-align: center;
  }
  .flex{
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    column-gap: 0px;
    div{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--text1-color);
        border: 1px solid var(--text1-color);
        width: 100px;
        height: 30px;
        border-radius: 15px;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
  .selectedBank{
    .title{
      position: relative;
      .back{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 12px;
      }
    }
    .btns{
      display: flex;
      justify-content: center;
      gap: 24px;
      button{
        background: transparent;
        padding: 5px 8px;
        color: var(--text1-color);
        border: 1px solid var(--text1-color);
        border-radius: 15px;
        &:disabled{
          border-color: gray;
          color:gray;
          cursor: not-allowed;
        }
      }
    }
    .checkboxes{
      display: flex;
      justify-content: center;
      gap: 10px;
      p{
        border: 1px solid  var(--text1-color);
        color: var(--text1-color);
        border-radius: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        font-size: 15px;
        line-height: 15px;
        align-items: center;
      }
      p.active{
        background: #26ed6c8a
      }
    }
    .title1{
      width: 100%;
      margin-bottom: 15px;
      font-size: 15px;
      color: var(--text1-color);
      text-align: center;
    }
  }
`;
