import styled from "styled-components";

export const StyledPageTitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    margin-bottom: 20px;
    color: var(--text1-color);

    .back {
        position: absolute;
        left: 0;
        cursor: pointer;
        font-weight: 300;
        display: flex;
        align-items: center;
        line-height: 10px;
        font-size: 14px;

        svg {
            font-size: 16px;
        }
    }

    .title {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-weight: 700;
        font-size: 17px;
        white-space: nowrap;
    }

    .edit {
        position: absolute;
        right: 0;
        text-align: center;
        cursor: pointer;
        font-size: 12px;
        max-width: 100px;
        svg{
            margin-left: 8px;
        }
    }

    .underline {
        text-decoration: underline;
    }
`;
