import styled from "styled-components";

export const SubModalContent = styled.div`
  position: relative;

  .text {
    color: var(--text1-color);
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin-top: 50px;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--text1-color);
    color: var(--text1-color);
    text-decoration: none;
    width: 154px;
    height: 48px;
    border-radius: 30px;
    margin: 40px auto 0 auto;
    font-weight: 500;
    font-size: 13px;
  }
`;
