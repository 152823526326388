import { SubscribeWrapper } from "./styled";
import PageTitle from "../../components/pageTitle";
import BanksModal from "../../components/modals/banksModal";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthProfile } from "../../store/features/auth/selectors";
import { getMyProfile } from "../../store/features/auth/slice";
import { formatDateReverse } from "../../helpers/helper";
import { PiQuestion } from "react-icons/pi";
import PhotoReportTextModal from "../../components/modals/photoReportModal";

const Subsctibe = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const myProfile = useSelector(selectAuthProfile())
  const dispatch = useDispatch()
  const [showInfoModal, setShowInfoModal] = useState(false);

  useEffect(() => {
    if (!myProfile) {
      dispatch(getMyProfile())
    }
  }, [dispatch, myProfile])

  return (
    <SubscribeWrapper>
      <PageTitle title="Управление подпиской" backFunc={() => navigate(-1)} editFunc={() => setShowInfoModal(true)} editText={<PiQuestion className="questionIcon" />} />
      {myProfile?.subscription_status === 'ACTIVE' || myProfile?.subscription_status === 'ACTIVE_PENDING' ?
        <p className="text">Ваша подписка активна до {formatDateReverse(new Date(myProfile?.subscription_end))}</p>
        :
        <p className="text">У вас нет подписки</p>
      }
      <button onClick={() => setOpenModal(true)}>Продлить подписку</button>

      <BanksModal open={openModal} handleModal={() => setOpenModal(false)} />
      <PhotoReportTextModal text={"Для оплаты подписки, выберете банк для перевода, переведите всю сумму и после перевода, зайдите обратно в приложение и нажмите на кнопку «оплатить», после чего поле с соответствующим количеством месяцев станет зелёным. Далее нажмите на кнопку оплачено. После проверки платежа Вам откроется доступ в приложение."}
        open={showInfoModal} handleModal={() => setShowInfoModal(false)} />
    </SubscribeWrapper>
  );
};
export default Subsctibe;
