import styled from "styled-components";

export const StyledWrapper = styled.div`
    width: 100%;
    height: 48px;
    position: relative;
    border-radius: 24px;
    background-color: #6E6C8E37;
    .icon{
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 22px;
        color: var(--text1-color);
    }
    input{
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        font-family: "TT Travels Trl", sans-serif;
        position: absolute;
        left: 0;
        top: 0;
        padding-left: 40px;
        color: var(--text1-color);
        font-size: 15px;
        font-weight: 300;
        &::placeholder{
            color: var(--text1-color);
        }
    }
`