import styled from "styled-components";
import { StyledCalendarWrapper } from "../../calendar/styled";

export const BanksModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title{
    width: max-content;
    font-size: 15px;
    color: var(--text1-color);
    text-align: center;
    margin:0 auto 20px auto;
  }
  button.save{
    background: transparent;
    width: max-content;
    margin-top: 20px;
    border: 1px solid var(--text1-color);
    color: var(--text1-color);
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 15px;
  }
  .save:disabled{
    color: gray;
    cursor: default;
    border-color:gray;
  }
  ${StyledCalendarWrapper}{
    width: 100%;
    background-color: transparent;
    border: none;
    margin: 0 auto;
    .calendar .item{
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }
  
`;
