import { useState } from "react";
import { SelectWrapper, StyledDrop, StyledDropItem } from "./styled";
import { IoIosArrowDown, IoMdCheckmark, IoMdClose } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router";

const CalendarSelect = ({ options, label }) => {
  const navigate = useNavigate()

  let month = new Date().getMonth() + 1
  let year = new Date().getFullYear()
  month = month < 10 ? `0${month}` : month
  const [active, setActive] = useState(false);

  return (
    <SelectWrapper
      $active={active}
      onClick={() => {
        setActive(!active);
      }}
    >
      <IoIosArrowDown className="arrow" />
      <div className="value">
        <p>{label}</p>
        <StyledDrop onClick={(e) => {
          e.stopPropagation()
        }}>
          {options?.map((item, index) => {
            const date = item.day < 10 ? '0' + item.day : item.day
            const past = new Date().getDate() > +item.day

            return (
              <StyledDropItem
                key={index}
              >
                <div>
                  <p
                    onClick={(e) => {
                      e.preventDefault()
                      navigate(`/yourWorkout/${item.day}`)
                    }} className="date">{date + '.' + month + '.' + year}</p>
                </div>
                <input
                  type="checkbox" id={`myCheckbox${index}`} />
                <label
                  onClick={(e) => {
                    e.preventDefault()
                    navigate(`/yourWorkout/${item.day}`)
                  }}
                  htmlFor={`myCheckbox${index}`}
                  className={`round-checkbox ${item.completed ? 'success' : 'error'}`}
                >
                  {item.completed ?
                    <IoMdCheckmark />
                    :
                    !item.completed && past &&
                    <IoMdClose />
                  }
                </label>
                <MdEdit className="editIcon" onClick={(e) => {
                  e.preventDefault()
                  navigate(`/yourWorkout/${item.day}`)
                }} />
              </StyledDropItem>
            );
          })}
        </StyledDrop>
      </div>
    </SelectWrapper >
  );
};

export default CalendarSelect;
