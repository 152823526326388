import styled from "styled-components";

export const UpdateWrapper = styled.div`
    max-width: 410px;
    padding: 45px 0;
    margin: 0 auto;
  
    .registrForm {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      gap: 15px;
      button {
        background-color: var(--text1-color);
        border: none;
        width: 170px;
        color: var(--button-color);
        font-weight: 700;
        font-size: 20px;
        border-radius: 30px;
        height: 46px;
        margin: 10px auto;
      }
    }
`;
