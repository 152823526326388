import { Formik } from "formik";
import { RegistrationWrapper } from "./styled";
import Input from "../../components/input";
import Select from "../../components/select";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthPurpose } from "../../store/features/auth/selectors";
import { getMyProfile, getPurpose, onRegister } from "../../store/features/auth/slice";
import { genders } from "../../constants";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Registration = () => {
  const purposes = useSelector(selectAuthPurpose());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tg = window.Telegram.WebApp;
  const userId = tg?.initDataUnsafe?.user?.id;
  // const userId = '1415473581'

  const [initialState, setIntialState] = useState({
    first_name: "",
    last_name: "",
    age: "",
    height: "",
    weight: "",
    fat_percent: null,
    number_workouts_per_week: 0,
    average_number_steps_per_week: 0,
    group_id: "",
    gender: "",
    telegram_user_id: "",
  });
  useEffect(() => {
    if (userId) {
      setIntialState({
        first_name: "",
        last_name: "",
        age: "",
        height: "",
        weight: "",
        fat_percent: null,
        number_workouts_per_week: 0,
        average_number_steps_per_week: 0,
        group_id: "",
        gender: "",
        telegram_user_id: String(userId),
      });
    }
  }, [userId]);

  const handleSubmitForm = async (values) => {
    if (!values.fat_percent || !values.fat_percent.length) {
      values.fat_percent = null
    }
    dispatch(onRegister(values)).then((res) => {
      if (res && res.payload) {
        dispatch(getMyProfile())
        navigate("/");
      }
    });
  };

  return (
    <RegistrationWrapper>
      <div className="title">Личные данные</div>

      <Formik
        initialValues={initialState}
        enableReinitialize
        onSubmit={handleSubmitForm}
      >
        {(formik) => {
          const {
            setFieldValue,
            values,
            handleChange,
            handleSubmit,
            // errors,
            // touched,
            // handleBlur,
          } = formik;

          return (
            <form onSubmit={handleSubmit} className="registrForm">
              <Input
                type="text"
                value={values.first_name}
                label="Имя"
                onchange={handleChange}
                name="first_name"
              />
              <Input
                type="text"
                value={values.last_name}
                label="Фамилия"
                onchange={handleChange}
                name="last_name"
              />
              <Input
                type="text"
                value={values.age}
                inputType='numeric'
                label="Возраст"
                onchange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleChange(e);
                  }
                }}
                name="age"
              />
              <Input
                type="text"
                inputType='numeric'
                value={values.height}
                label="Рост"
                onchange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleChange(e);
                  }
                }}
                name="height"
              />
              <Input
                type="text"
                value={values.weight}
                inputType='numeric'
                label="Вес"
                onchange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleChange(e);
                  }
                }}
                name="weight"
              />
              <Input
                type="text"
                value={values.fat_percent ? values.fat_percent : ''}
                inputType='numeric'
                label="% жира"
                onchange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleChange(e);
                  }
                }}
                name="fat_percent"
              />
              <Input
                type="text"
                inputType='numeric'
                value={
                  values.number_workouts_per_week
                    ? String(values.number_workouts_per_week)
                    : ""
                }
                label="Количество тренировок в неделю"
                onchange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleChange(e);
                  }
                }}
                name="number_workouts_per_week"
              />
              <Input
                type="text"
                inputType='numeric'
                value={
                  values.average_number_steps_per_week
                    ? String(values.average_number_steps_per_week)
                    : ""
                }
                label="Среднее количество шагов в день"
                onchange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleChange(e);
                  }
                }}
                name="average_number_steps_per_week"
              />
              <Select
                options={genders}
                value={values.gender}
                label="Пол"
                onchange={(e) => {
                  dispatch(getPurpose(e.id));
                }}
                name="gender"
                setFieldValue={setFieldValue}
              />
              <Select
                options={purposes?.rows?.filter(i => i.gender !== 'UNKNOWN')}
                value={values.group_id}
                label="Цель занятий"
                onchange={() => { }}
                name="group_id"
                setFieldValue={setFieldValue}
              />
              <button type="submit">Продолжить</button>
            </form>
          );
        }}
      </Formik>
    </RegistrationWrapper>
  );
};
export default Registration;
