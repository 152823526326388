import styled, { css } from "styled-components";



export const StyledDrop = styled.div``

export const SelectWrapper = styled.div`
  position: relative;
  margin: 20px 0;
  label {
    position: absolute;
    left: 10px;
    top: 50%;
    font-size: 15px;
    font-weight: 500;
    transform: translateY(-45%);
    line-height: 15px;
    color: var(--text1-color);
    padding: 0 5px;
    z-index: 2;
    transition: 0.3s;
    border-radius: 0;
    transform-origin: left;
    ${(props) =>
    props.$activeLabel
      ? css`
            background: var(--background);
            transform: translateY(-45%) scale(0.8);
            top: 0;
          `
      : css``}
  }
  .arrow {
    color: var(--text1-color);
    position: absolute;
    right: 10px;
    top: 0%;
    font-size: 22px;
    font-weight: 300;
    transform: translateY(50%);
    transition: 0.3s;
    ${(props) =>
    props.$active
      ? css`
            transform: translateY(50%) rotate(180deg);
          `
      : null}
  }
  .value {
    width: 100%;
    outline: none;
    border-radius: 9px;
    border: 2px solid var(--text1-color);
    height: 48px;
    color: var(--text1-color);
    background-color: transparent;
    padding-left: 12px;
    p {
      margin-top: 12px;
      height: 21px;
    }
    ${StyledDrop} {
      margin: 15px 0 15px 0;
      font-size: 14px;
      font-weight: 500;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      input[type="checkbox"] {
          display: none;
      }

      .round-checkbox {
          position:static;
          transform:none;
          display:flex;
          align-items:center;
          justify-content:center;
          padding: 0;
          width: 26px; 
          height: 26px;
          border-radius: 50%; 
          border: 1px solid var(--text1-color);
          svg{
            font-size:22px;
          }
      }
      .round-checkbox.success {
        color: #0FBE00;
      }
      .round-checkbox.error {
          color: red;
      }

      input[type="checkbox"]:checked + .round-checkbox {
        color: #0FBE00 !important;
      }

      .editIcon{
        font-size:18px;
        color:var(--text1-color)
      }
    }
    ${(props) => props.$active ? css`
      height: auto;
      ${StyledDrop} {
        height: auto;
      }
      ` : css`
      ${StyledDrop} {
        height: 0;
        display: none;
      }
      `}
  }
`;

export const StyledDropItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  div{
    width: 60%;
    height: 26px !important;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 14px;
    padding: 0 8px;
    border-radius: 9px;
    margin: 0 !important;
    border:1px solid var(--text1-color);
    p {
      color: var(--text1-color);
      font-size: 13px;
      line-height: 13px;
    }
    p.date{
      margin-top: 0;
      height: auto;
    }
  }
  `
