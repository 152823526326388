import { useEffect, useState } from "react";
import image1 from "../../images/home1.png";
import image2 from "../../images/home2.png";
import { StartPageWrappers } from "./styled";
import { FaAngleRight } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserExist } from "../../store/features/auth/slice";
import LoadingComp from "../../components/loading";

const StartPage = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const handleStart = () => {
    navigate("/registration");
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await dispatch(getUserExist())
      if (res && (location.pathname === '/start' || location.pathname === '/')) {
        if (res.payload) {
          setLoading(false)
          navigate('/')
        } else {
          setLoading(false)
          navigate('/start')
        }
      }
    }
    fetchData()
  }, [dispatch, location?.pathname, navigate])
  return (
    <>
      {!loading ?
        <StartPageWrappers>
          <div className="firstImage">
            <img src={image1} alt="" />
          </div>
          <p className="bigText">Добро пожаловать в Top G fitness</p>
          <p className="smallText">
            Это приложение поможет тебе достичь желаемых результатов эффективнее и
            быстрее
          </p>
          <button onClick={handleStart} className="button">
            Начать
            <FaAngleRight className="rightArrow" />
          </button>
          <div className="secondIMage">
            <img src={image2} alt="" />
          </div>
          <div className="creator">
            <Link
              to='https://t.me/maybeDeveloper'
              target='blank'
            >Сделано @maybeDeveloper</Link>
          </div>
        </StartPageWrappers>
        : <LoadingComp />}
    </>
  );
};

export default StartPage;
