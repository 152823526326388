import React from "react";
import { SuccessModalContent } from "./styled";
import Modal from "react-modal";
import successIcon from "../../../images/icons/success.png";

const SuccessModal = ({ open, handleModal }) => {
  Modal.setAppElement("#root");
  const app = document.querySelector(".App");
  const isLightTheme = app?.classList?.value.includes("light");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "340px",
      background: "var(--background)",
      borderRadius: "21px",
      border: "2px solid var(--text1-color)",
    },
    overlay: {
      background: "transparent",
    },
  };


  return (
    <Modal
      className={isLightTheme ? "light lightModal" : ""}
      isOpen={open}
      onRequestClose={() => handleModal(false)}
      style={customStyles}
    >
      <SuccessModalContent>
        <p className="title">Успешно</p>
        <img src={successIcon} alt="check" />
      </SuccessModalContent>
    </Modal>
  );
};

export default SuccessModal;
