import styled from "styled-components";

export const ModalContent = styled.div`
  display: flex;  
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--text1-color);
  font-weight: 700;
  img{
    height: 91px;
    width: 91px;
    margin: 20px 0;
  }
`;
