import styled from "styled-components";

export const StyledTechnique = styled.div`
    max-width: 410px;
    padding: 45px 0;
    margin: 0 auto;
    min-height: 100vh;

    .moreBtn {
        cursor: pointer;
        background: var(--text1-color);
        color: var(--background);
        width: max-content;
        margin: 0 auto;
        font-size: 14px;
        font-weight: 700;
        display: block;
        border: none;
        border-radius: 30px;
        padding: 10px 30px;
    }
`

export const StyledTechniqueItem = styled.div`
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
    border: 2px solid var(--text1-color);
    color: var(--text1-color);
    border-radius: 8px;
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;
    padding: 0 10px;
    width: 100%;
    height: 48px;
`