import styled from "styled-components";

export const StyledChartWrapper = styled.div`
    position: relative;
    p{
        font-size: 13px;
        color: var(--text1-color);
        font-weight: 300;
        max-width: 70px;
        position: absolute;
    }
    .yTitle{
        top: -15px;
        left: 0;
        white-space: nowrap;
        width: max-content;
    }
    .xTitle{
        text-align: center;
        bottom: -30px;
        right: 0;
    }
`