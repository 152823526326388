import { StyledAddReview } from "./styled";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../../components/pageTitle";
import { useDispatch } from "react-redux";
import Star from "../../../components/stars";
import { postReview } from "../../../store/features/customs/slice";
import ReviewSuccessModal from "../../../components/modals/reviewSuccessModal";

const AddReview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [description, setDescription] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [stars, setStars] = useState([
    'empty',
    'empty',
    'empty',
    'empty',
    'empty',
  ])


  const handleSave = () => {
    const count = stars.filter(i => i === 'active').length
    const data = {
      description,
      stars: count
    }
    dispatch(postReview(data)).then(res => {
      if (res.payload) {
        setShowSuccessModal(true)
        setTimeout(() => {
          setShowSuccessModal(false)
          navigate(-1)
        }, 3000)
      }
    })
  }
  const handleStarClick = (index) => {
    const newStars = [...stars];
    for (let i = 0; i < stars.length; i++) {
      newStars[i] = i <= index ? 'active' : 'empty';
    }
    setStars(newStars);
  };

  return (
    <StyledAddReview>
      <PageTitle title="Отзывы" backFunc={() => navigate(-1)} />
      <br />
      <div className="stars">
        {stars?.map((item, index) => {
          return (
            <Star
              key={index}
              type={item === 'active' ? '' : 'empty'}
              onclick={() => handleStarClick(index)}
              color={`var(--text1-color)`} />
          )
        })}
      </div>
      <br />
      <textarea value={description} onChange={(e) => setDescription(e.target.value)}
        name="" id="" placeholder="Введите текст отзыва" rows="10"></textarea>
      <br />
      <br />
      <button onClick={handleSave}>Оставить отзыв</button>
      <br />
      <ReviewSuccessModal open={showSuccessModal} handleModal={() => setShowSuccessModal(false)} />
    </StyledAddReview>
  );
};
export default AddReview;
