import styled from "styled-components";

export const StartPageWrappers = styled.div`
  margin: 0 auto;
  max-width: 410px;
  padding-top: 15px;
  height: 100vh;
  .firstImage {
    height: 20vh;
    display: flex;
    justify-content: flex-end;
  }
  .secondIMage {
    height: 20vh;
    display: flex;
  }
  p {
    color: var(--text1-color);
  }

  .bigText {
    font-size: 8vw;
    font-weight: 700;
    text-align: center;
    margin: 8vh 0;
  }

  .smallText {
    font-size: 5vw;
    font-weight: 500;
    text-align: center;
    margin-bottom: 5vh;
  }
  button {
    text-decoration: none;
    position: relative;
    margin: 0 auto;
    width: 177px;
    border: 2px solid var(--text1-color);
    border-radius: 30px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 10px;
    color: var(--text1-color);
    background-color: var(--back-color);
    margin-bottom: 5vh;
    .rightArrow {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;
      font-weight: 300;
    }
  }
  .creator{
      width: 80%;
      margin: 0 auto;
      text-align: center;
      border-radius: 12px;
      background-color: #606060;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 0;
      a{
        font-size: 14px;
        font-weight: 500;
        color: white;
        text-decoration: none;
      }
    }
`;
