import styled from "styled-components";
import { StyledCalendarWrapper } from "../../../components/calendar/styled";
import { StyledImageDrop } from "../styled";


export const StyledAddPage = styled.div`
    padding: 45px 0;
    max-width: 410px;
    margin: 0 auto;
    .grid{
        margin-top: 40px;
        display: grid;
        gap: 8px;
        grid-template-columns: repeat(2, 1fr);
        div{
            min-width: 160px;
            max-width: 200px;
            height: 200px;
            background-color: #d9d9d9;
        }
        .preview{
            position: relative;
            p{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                text-align: center;
                font-size: 13px;
                font-weight: 300;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    ${StyledCalendarWrapper}{
        border: none;
        margin: 0;
        padding: 0;
        width: 100%;
        background-color: transparent;
        .calendar{
            margin-top: -15px;
        }
    }
    ${StyledImageDrop}{
        background: ${(props) => props.open ? 'var(--calendar-back)' : 'transparent'}
    }
    .saveBtn{
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid var(--text1-color);
        color: var(--text1-color);
        border-radius: 30px;
        height: 45px;
        width: 154px;
        background: transparent;
        margin: 30px auto 0 auto;
        font-size: 16px;
        font-weight: 700;
        &:disabled{
            border-color:gray;
            color:gray 
        }
    }
    .questionIcon{
        font-size: 24px;
    }
`;
