import styled from "styled-components";

export const StyledAddReview = styled.div`
    max-width: 410px;
    padding: 45px 0;
    margin: 0 auto;
    min-height: 100vh;
    .stars{
        display: flex;
        gap: 4px;
    }
    button{
        cursor: pointer;
        background: var(--text1-color);
        color: var(--background);
        width: max-content;
        margin: 0 auto;
        font-size: 14px;
        font-weight: 700;
        display: block;
        border: none;
        border-radius: 30px;
        padding: 10px 30px;
    }
    textarea{
        width: 100%;
        background: transparent;
        resize: none;
        border: 2px solid var(--text1-color);
        color: var(--text1-color);
        font-size: 13px;
        font-weight: 300;
        border-radius: 15px;
        padding: 12px;
        &::placeholder{
            color: var(--text1-color)
        }
    }
`