import React from "react";
import { StyledCalendarWrapper } from "./styled";
import Calendar from "react-calendar";

const CalendarComp = ({ onchange, value, tileContent }) => {
  const tileDisabled = ({ date }) => {
    return date.getMonth() !== new Date().getMonth();
  };
  const tileClassName = ({ date }) => {
    return tileDisabled({ date }) ? 'disabled' : 'item';
  };
  return (
    <>
      <StyledCalendarWrapper>
        <Calendar
          onChange={onchange}
          value={value}
          tileContent={tileContent}
          tileDisabled={tileDisabled}
          tileClassName={tileClassName}
          className="calendar"
          nextLabel={false}
          next2Label=""
          prev2Label=""
          prevLabel=""
          navigationLabel={({ label }) => label.split(" ")[0]}
          minDetail="month"
          showNeighboringMonth={true}
          locale="ru"
        />
      </StyledCalendarWrapper>
    </>
  );
};

export default CalendarComp;
