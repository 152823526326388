import styled from "styled-components";

export const AddDataWrapper = styled.div`
    max-width: 410px;
    padding: 45px 0;
    margin: 0 auto;
    button{
        width: 154px;
        height: 48px;
        margin: 0 auto;
        gap: 8px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: var(--text1-color);
        border: 2px solid var(--text1-color);
        font-size: 16px;
        font-weight: 700;
    }
    .questionIcon{
        font-size: 24px;
    }
`;

