import styled, { css } from "styled-components";



export const DropDownWrapper = styled.div`
    position: relative;
    outline: none;
    border-radius: 9px;
    border: 2px solid var(--text1-color);
    color: var(--text1-color);
    background-color: transparent;
    padding: 12px;
    margin-top: 16px;

    ${(props) =>
            props.$active
                    ? css`
                        height: auto;
                    `
                    : css`
                        height: 48px;
                    `}
    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 2;
        font-size: 15px;
        font-weight: 300;
        color: var(--text1-color);
    }

    .arrow {
        font-size: 22px;
        font-weight: 300;
        ${(props) =>
                props.$active
                        ? css`
                            transform: rotate(180deg);
                        `
                        : null}
    }

    .text {
        font-size: 13px;
        font-weight: 300;
        white-space: pre-wrap;
        word-wrap: break-word;
        max-width: 100%;

        ${(props) =>
                props.$active
                        ? css`
                            margin-top: 16px;
                            height: auto;
                        `
                        : css`
                            height: 0;
                            display: none;
                        `}
    }

`;
