import styled, { css } from "styled-components";

export const StyledPhotoReport = styled.div`
    max-width: 410px;
    padding: 45px 0;
    margin: 0 auto;
    min-height: 100vh;
    .plusBtn{
        width: 56px;
        height: 56px;
        margin: 0 auto;
        border-radius: 50%;
        border: 5px solid var(--text1-color);
        color: var(--text1-color);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        svg{
            font-size: 24px;
        }
    }
`

export const StyledImageDrop = styled.div`
        width: 100%;
        text-align: center;
        margin-top: 20px;
        border: 2px solid var(--text1-color);
        border-radius: 8px;
        height: ${(props) => props.open ? 'auto' : '48px'};
        padding: 12px;
        color: var(--text1-color);
        .title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            font-size: 15px;
            font-weight: 500;
            text-align: start;
            .arrow{
                ${(props) => props.open ? css`
                transform:rotate(180deg);
                ` : null}
            }
        }
        .drop{
            margin-top: 10px;
            height: ${(props) => props.open ? 'auto' : '0'};
            display: ${(props) => props.open ? 'flex' : 'none'};
            overflow-x: auto;
            width: 100%;
            gap: 5px;
            /* scroll-snap-type: x mandatory; */
            .img{
                background-color:#d9d9d9;
                width: 100%;
                height: 100%;
                object-fit:cover;

                /* width: 97px; */
                /* min-width: 97px; */
                /* height: 97px; */
                img{
                    width: 100%;
                    height: 100%;
                    object-fit:cover;
                }
            }
            &::-webkit-scrollbar {
                display: none;
            }
}
`
