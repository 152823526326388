import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export function createClientWithToken(userId) {
    return axios.create({
        baseURL: BASE_URL,
        headers: {
            'Content-Type': 'application/json',
            'token': userId
        }
    });
}

export function createClientWithTokenFORM(userId) {
    return axios.create({
        baseURL: BASE_URL,
        headers: {
            'Content-Type': 'multipart/form-data',
            'token': userId
        }
    });
}

export async function sendRequestWithToken(requestData) {
    const tg = window.Telegram.WebApp;
    const user = tg.initDataUnsafe?.user?.id;
    // const user = 1415473581
    const client = createClientWithToken(user);
    try {
        const response = await client(requestData);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function sendRequestWithTokenFORM(requestData) {
    const tg = window.Telegram.WebApp;
    const user = tg.initDataUnsafe?.user?.id;
    const client = createClientWithTokenFORM(user);
    try {
        const response = await client(requestData);
        return response.data;
    } catch (error) {
        throw error;
    }
}


