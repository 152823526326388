import Compressor from 'compressorjs';

export const compressImage = file => new Promise((resolve, reject) => {

    const compress = (file) => {
        new Compressor(file, {
            quality: 0.7,
            maxWidth: 650,
            convertSize: 5000000,
            success: (compressedResult) => {
                resolve(compressedResult);
            },
            error: (err) => {
                reject(err);
            },
        });
    };

    compress(file);
});