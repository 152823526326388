import { DashboardWrapper } from "./styled";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearCustomData, getCustom } from "../../store/features/customs/slice";
import DropDown from "../../components/dropDown";
import PageTitle from "../../components/pageTitle";
import { selectData } from "../../store/features/customs/selectors";

const titleTranslate = {
    Updates: "Обновления",
    InterestingFacts: "Интересные факты",
    ScientificResearch: "Научные исследования",
    ApplicationOperation: "Работа приложения",
    DiscountsAndPromotions: "Скидки и акции",
    QuestionAnswer: "Помощь",
}

const toUnderlineModel = {
    Updates: "updates",
    InterestingFacts: "interesting_facts",
    ScientificResearch: "scientific_research",
    ApplicationOperation: "application_operation",
    DiscountsAndPromotions: "discounts_and_promotions",
    QuestionAnswer: "question_answer",
}
const CustomSinglePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { type } = useParams();
    const data = useSelector(selectData())
    const [selected, setSelected] = useState()
    useEffect(() => {
        dispatch(getCustom(type))
        return () => {
            dispatch(clearCustomData())
        }
    }, [dispatch, type])

    const handleSelect = (id) => {
        if (selected === id) {
            setSelected(null)
        } else {
            setSelected(id)
        }
    }

    return (
        <DashboardWrapper>
            <PageTitle title={titleTranslate[type]} backFunc={() => navigate(-1)} />
            {data?.rows?.map((item) => {
                const key = toUnderlineModel[type]
                const files = item[key]
                return (
                    <DropDown
                        key={item.id}
                        files={files}
                        open={selected === item.id}
                        label={item.title}
                        text={item.description}
                        onClick={() => handleSelect(item.id)}
                    />
                )
            })}
            <br />
            <br />
        </DashboardWrapper>
    );
};
export default CustomSinglePage;
