import React, { useEffect, useState } from "react";
import { StyledWorkout } from "./styled";
import PageTitle from "../../components/pageTitle";
import CalendarComp from "../../components/calendar";
import CalendarSelect from "../../components/calendar/dropDown";
import { useNavigate } from "react-router";
import { getMyProfile } from "../../store/features/auth/slice";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthProfile } from "../../store/features/auth/selectors";

const Workout = () => {
  const myProfile = useSelector(selectAuthProfile());
  const dispatch = useDispatch()

  const [upcomung, setUpcomung] = useState([]);
  const [past, setPast] = useState([]);


  useEffect(() => {
    if (!myProfile) {
      dispatch(getMyProfile());
    }
  }, [dispatch, myProfile]);
  const navigate = useNavigate();

  const getBackground = (day) => {
    if (day <= new Date().getDate()) {
      if (myProfile?.UserExercise?.completed_days?.includes(day)) {
        return "#538B4E";
      }
      return "#A7565177";
    } else if (day > new Date().getDate()) {
      return "#ccc";
    } else {
      return "transparent";
    }
  };

  const tileContent = ({ date, view }) => {
    if (view === "month" && date.getMonth() === new Date().getMonth()) {
      const day = date.getDate();
      if (myProfile?.UserExercise?.all_days.includes(day)) {
        return (
          <p
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              background: getBackground(day),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 500,
              fontSize: "15px",
              color: "var(--text1-color)",
            }}
          >
            {day}
          </p>
        );
      }
    }
  };
  useEffect(() => {
    const day = new Date().getDate();

    if (myProfile?.UserExercise) {
      const keys = Object.keys(myProfile?.UserExercise.exercises)
      setPast(
        keys
          .filter((i) => +i < day || myProfile?.UserExercise?.completed_days.includes(+i)).map(item => {
            const completed = !!myProfile.UserExercise.completed_days.find(i => i === +item)
            return {
              day: item,
              completed
            }
          })
      );

      setUpcomung(
        keys
          .filter((i) => +i >= day && !myProfile?.UserExercise?.completed_days.includes(+i)).map(item => {
            const completed = !!myProfile.UserExercise.completed_days.find(i => i === +item)
            return {
              day: item,
              completed
            }
          })
      );
    }
  }, [myProfile]);

  return (
    <StyledWorkout>
      <PageTitle title="Тренировки" backFunc={() => navigate(-1)} />
      <CalendarComp
        tileContent={tileContent}
      />
      <CalendarSelect options={upcomung} label="Предстоящие тренировки" />
      <CalendarSelect options={past} label="Прошедшие тренировки" />
    </StyledWorkout>
  );
};

export default Workout;
