import React from "react";
import { PriceModalContent } from "./styled";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";

const PriceModal = ({ open, handleModal, selected, price, setConfirmed }) => {
  Modal.setAppElement("#root");
  const app = document.querySelector(".App");
  const isLightTheme = app?.classList?.value.includes("light");
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "340px",
      background: "var(--background)",
      borderRadius: "21px",
      border: "2px solid var(--text1-color)",
    },
    overlay: {
      background: "transparent",
    },
  };
  const handleConfirm = () => {
    setConfirmed(selected)
    handleModal()
  }
  return (
    <Modal
      className={isLightTheme ? "light lightModal" : ""}
      isOpen={open}
      onRequestClose={() => handleModal(false)}
      style={customStyles}
    >
      {open && (
        <IoIosClose
          className="modalCloseIcon"
          onClick={() => handleModal(false)}
        />
      )}
      <PriceModalContent>
        <div className="selectedBank">
          <div className="title">За {selected} месяцев {selected * price} рублей</div>
          <div className="btns">
            <button onClick={handleConfirm}>Оплатить</button>
            <button onClick={handleModal} >Отмена</button>
          </div>
        </div>
      </PriceModalContent>
    </Modal>
  );
};

export default PriceModal;
